import { Controller } from "@hotwired/stimulus";
import Swiper, { Autoplay, EffectCoverflow, EffectFade, FreeMode, Lazy, Navigation, Pagination } from "swiper";
export default class default_1 extends Controller {
    static values = {
        options: Object
    };
    static targets = [
        "swiper"
    ];
    swiper;
    connect() {
        let options = {
            ...this.defaultOptions,
            ...this.optionsValue,
            ...{
                onAny: (eventName, ...args) => {
                    if (eventName === 'afterInit') {
                        this.dispatch('afterInit');
                    }
                }
            }
        };
        // @ts-ignore
        this.swiper = new Swiper(this.hasSwiperTarget ? this.swiperTarget : this.element, options);
        this.swiper.on('activeIndexChange', _ => {
            this.dispatch("activeIndexChange", {
                detail: {
                    activeIndex: this.getActiveIndex()
                }
            });
        });
    }
    disconnect() {
        this.swiper?.destroy();
        this.swiper = undefined;
    }
    slideNext() {
        this.swiper?.slideNext();
    }
    slidePrev() {
        this.swiper?.slidePrev();
    }
    getActiveIndex() {
        return this.swiper?.activeIndex;
    }
    get defaultOptions() {
        return {
            preventClicks: true,
            modules: [Autoplay, Navigation, Pagination, Lazy, EffectFade, EffectCoverflow, FreeMode],
            "lazy": {
                "loadPrevNext": true,
                "loadOnTransitionStart": false,
                "checkInView": true
            },
            "preloadImages": false,
            "navigation": {
                "nextEl": ".swiper-button-next",
                "prevEl": ".swiper-button-prev",
                "disabledClass": "swiper-button-disabled",
                "lockClass": "swiper-button-lock"
            },
            "pagination": {
                "el": ".swiper-pagination",
                "clickable": true,
                "dynamicBullets": true
            }
        };
    }
}
