import '../styles/popover.css';
import { Controller } from '@hotwired/stimulus';
import { createPopper } from "@popperjs/core";
import { useClickOutside } from "stimulus-use";
export default class default_1 extends Controller {
    hasButtonTarget;
    hasContentTarget;
    hasUrlValue;
    contentTarget;
    buttonTarget;
    urlValue;
    remoteContent;
    placementValue;
    offsetValue;
    static targets = ['button', 'content'];
    static values = {
        url: String,
        placement: { type: String, default: 'auto' },
        offset: { type: Number, default: 0 },
    };
    popperInstance;
    connect() {
        super.connect();
        useClickOutside(this);
        if (this.hasButtonTarget && this.hasContentTarget) {
            this.popperInstance = createPopper(this.buttonTarget, this.contentTarget, {
                placement: this.placementValue,
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, this.offsetValue],
                        },
                    },
                ],
            });
        }
    }
    show(e) {
        this.contentTarget.setAttribute('data-show', '');
        this.popperInstance.update();
    }
    hide(e) {
        this.contentTarget.removeAttribute('data-show');
    }
    toggle(e) {
        if (this.contentTarget.hasAttribute('data-show')) {
            this.hide(e);
        }
        else {
            this.show(e);
        }
    }
    clickOutside() {
        this.hide();
    }
}
