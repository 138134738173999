import { Controller } from '@hotwired/stimulus';
import { useIntersection } from "stimulus-use";
export default class default_1 extends Controller {
    // @ts-ignore
    element;
    static values = {
        url: String,
        instant: Boolean
    };
    static targets = [
        'spinner',
        'geduld1',
        'geduld2',
        'error',
    ];
    contentLoaded = false;
    async connect() {
        if (this.instantValue) {
            this.contentLoaded = true;
            if (this.hasSpinnerTarget) {
                const [{ default: gsap, Power1 }, { DrawSVGPlugin },] = await Promise.all([
                    import("gsap"),
                    // @ts-ignore
                    import("gsap/DrawSVGPlugin")
                ]);
                gsap.registerPlugin(DrawSVGPlugin);
                gsap.timeline()
                    .repeat(-1)
                    .fromTo(this.spinnerTarget, {
                    drawSVG: "0%"
                }, {
                    drawSVG: "100%",
                    immediateRender: false,
                    ease: Power1?.easeInOut,
                    duration: 5,
                    rotation: 360,
                    transformOrigin: "50% 50%",
                    stroke: "#3399cc"
                })
                    .fromTo(this.spinnerTarget, {
                    drawSVG: "100%"
                }, {
                    drawSVG: "0%",
                    immediateRender: false,
                    ease: Power1?.easeInOut,
                    duration: 5,
                    rotation: -720,
                    transformOrigin: "50% 50%",
                    stroke: "#99CCFF"
                });
                if (this.hasGeduld1Target) {
                    gsap.timeline()
                        .set(this.geduld1Target, {
                        opacity: 0,
                    })
                        .to(this.geduld1Target, {
                        ease: Power1?.easeInOut,
                        duration: 1,
                        opacity: 100,
                        delay: 5
                    });
                }
                if (this.hasGeduld2Target) {
                    gsap.timeline()
                        .set(this.geduld2Target, {
                        opacity: 0,
                    })
                        .to(this.geduld2Target, {
                        ease: Power1?.easeInOut,
                        duration: 1,
                        opacity: 100,
                        delay: 10
                    });
                }
            }
            const response = await fetch(this.urlValue);
            if (response.ok) {
                this.element.innerHTML = await (response ? response.text() : Promise.resolve(''));
            }
            else if (this.hasErrorTarget) {
                this.element.innerHTML = this.errorTarget.innerHTML;
            }
        }
        else if ('IntersectionObserver' in window) {
            useIntersection(this);
        }
    }
    // noinspection JSUnusedGlobalSymbols
    async appear() {
        if (this.contentLoaded) {
            return;
        }
        this.contentLoaded = true;
        const response = await fetch(this.urlValue);
        this.element.innerHTML = await (response ? response.text() : Promise.resolve(''));
    }
}
