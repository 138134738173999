// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
// This example controller works with specially annotated HTML like:
// <div role="main" class="h-screen flex overflow-hidden bg-gray-100"
//      data-controller="slideover"
//      data-slideover-invisible-class="-translate-x-full,opacity-0"
//      data-slideover-visible-class="translate-x-0,opacity-100"
//      data-slideover-entering-class=""
//      data-slideover-enter-timeout="300,300"
//      data-slideover-leaving-class=""
//      data-slideover-leave-timeout="300,300"
//      data-slideover-active-target="#slideover-target">
//      <div id="sidebar">
//        <div data-slideover-target="overlay" class="fixed inset-0 flex z-40 transition-opacity ease-linear duration-300 opacity-0 hidden">
//          <div class="fixed inset-0">
//            <div class="absolute inset-0 bg-gray-600 opacity-75"></div>
//          </div>
//          <div id="slideover-target" data-slideover-target="menu" class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800 transition ease-in-out duration-300 transform -translate-x-full hidden">
//            <div class="absolute top-0 right-0 -mr-14 p-1">
//              <button data-action="slideover#_hide" class="flex items-center justify-center h-12 w-12 rounded-full focus:outline-hidden focus:bg-gray-600" aria-label="Close sidebar">
//                <svg class="h-6 w-6 text-white" stroke="currentColor" fill="none" viewBox="0 0 24 24">
//                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
//                </svg>
//              </button>
//            </div>
//          </div>
//        </div>
//      </div>
// </div>
// @ts-ignore
import { Dropdown } from "tailwindcss-stimulus-components";
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from './utils/bodyScrollLock';
export default class default_1 extends Dropdown {
    static targets = ['menu', 'overlay'];
    connect() {
        super.connect();
        // Event listener for the popstate event (triggered by back/forward buttons)
        window.addEventListener('popstate', (event) => {
            // Check if the modal was open
            if (!event.state || !event.state.slideoverOpen) {
                this._hide();
            }
        });
    }
    disconnect() {
        super.disconnect();
        clearAllBodyScrollLocks();
    }
    _show() {
        this.overlayTarget?.classList.remove(this.toggleClass);
        if (this.hasOverlayTarget) {
            disableBodyScroll(this.overlayTarget, {
                reserveScrollBarGap: true,
            });
        }
        super._show((() => {
            this._activeClassList[1].forEach(klass => this.overlayTarget?.classList.add(klass));
            this._invisibleClassList[1].forEach(klass => this.overlayTarget?.classList.remove(klass));
            this._visibleClassList[1].forEach(klass => this.overlayTarget?.classList.add(klass));
            setTimeout((() => {
                this._enteringClassList[1].forEach(klass => this.overlayTarget?.classList.remove(klass));
            }), this.enterTimeout[1]);
        }));
        window.history.pushState({ slideoverOpen: true }, '', '');
    }
    _hide() {
        this._leavingClassList[1].forEach(klass => this.overlayTarget?.classList.add(klass));
        if (this.hasOverlayTarget) {
            enableBodyScroll(this.overlayTarget);
        }
        super._hide((() => {
            setTimeout((() => {
                this._visibleClassList[1].forEach(klass => this.overlayTarget?.classList.remove(klass));
                this._invisibleClassList[1].forEach(klass => this.overlayTarget?.classList.add(klass));
                this._activeClassList[1].forEach(klass => this.overlayTarget?.classList.remove(klass));
                this._leavingClassList[1].forEach(klass => this.overlayTarget?.classList.remove(klass));
                this.overlayTarget?.classList.add(this.toggleClass);
            }), this.leaveTimeout[1]);
        }));
        if (window.history.state && window.history.state.slideoverOpen) {
            // Triggering history.back() keeps the browser history consistent
            window.history.back();
        }
    }
}
