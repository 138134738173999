import { Controller } from "@hotwired/stimulus";
export default class default_1 extends Controller {
    // @ts-ignore
    element;
    static values = {
        options: Object,
        autoplayDelay: { type: Number, default: 6000 }
    };
    static targets = [
        "swiper"
    ];
    swiper;
    gallery;
    async connect() {
        await this.initSwiper();
    }
    disconnect() {
        this.swiper?.destroy();
        this.swiper = undefined;
        this.gallery?.destroy();
        this.gallery?.destroyModules();
        this.gallery = undefined;
    }
    async initSwiper() {
        if (this.swiperSlidesCount <= 1) {
            this.swiperElement.querySelector(".swiper-lazy-preloader")?.remove();
            return Promise.resolve();
        }
        const { default: Swiper, Autoplay, EffectFade, Lazy, Pagination, Navigation } = await import("swiper");
        this.swiper = new Swiper(this.swiperElement, {
            loop: true,
            centeredSlides: true,
            slideToClickedSlide: false,
            autoplay: {
                delay: this.autoplayDelayValue,
                disableOnInteraction: false
            },
            // If we need pagination
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
                dynamicBullets: true
            },
            // Navigation arrows
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev"
            },
            // keyboard control
            keyboard: {
                enabled: true
            },
            modules: [Lazy, Autoplay, EffectFade, Pagination, Navigation],
            lazy: {
                loadPrevNext: true,
                loadOnTransitionStart: true,
                checkInView: true
            },
            effect: "fade",
            fadeEffect: {
                crossFade: true
            },
            speed: 1000,
            preloadImages: false
        });
        document.querySelector(".swiper-button-prev")?.addEventListener("click", (e) => {
            e.stopPropagation();
        });
        document.querySelector(".swiper-button-next")?.addEventListener("click", (e) => {
            e.stopPropagation();
        });
    }
    async initGallery() {
        const [{}, { default: lightGallery }, { default: Zoom }] = await Promise.all([
            // @ts-ignore
            import("../styles/lightgallery/lightgallery.css"),
            import("lightgallery"),
            import("lightgallery/plugins/zoom")
        ]);
        this.gallery = lightGallery(this.element, {
            licenseKey: "FDBAC5E3-08114657-964A7B01-72A24D21",
            speed: 500,
            download: false,
            plugins: [
                Zoom
            ],
            zoom: true,
            mobileSettings: {
                showCloseIcon: true,
            },
            selector: ".swiper-slide:not(.swiper-slide-duplicate)"
        });
        this.element.addEventListener("lgAfterSlide", (event) => {
            const { index } = event.detail;
            this.swiper?.slideTo(index + 1, 0); // +1 in Loop-Mode
        });
    }
    // noinspection JSUnusedGlobalSymbols
    async openGallery() {
        if (!this.gallery) {
            await this.initGallery();
        }
        this.gallery?.openGallery(this.swiper?.realIndex);
    }
    get swiperElement() {
        return this.hasSwiperTarget ? this.swiperTarget : this.element;
    }
    get swiperSlidesCount() {
        return this.swiperElement.querySelectorAll(".swiper-slide").length;
    }
}
