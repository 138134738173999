import { Controller } from '@hotwired/stimulus';
import { useIntersection } from "stimulus-use";
export default class default_1 extends Controller {
    // @ts-ignore
    element;
    contentLoaded = false;
    static targets = [
        'script',
    ];
    async connect() {
        useIntersection(this);
    }
    // noinspection JSUnusedGlobalSymbols
    async appear() {
        if (this.contentLoaded) {
            return;
        }
        this.contentLoaded = true;
        // replace data-src attribute with src on this.element
        const src = this.scriptTarget.getAttribute('data-src');
        if (!src) {
            return;
        }
        this.scriptTarget.setAttribute('src', src);
    }
}
