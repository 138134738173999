import { ApplicationController, useWindowResize } from "stimulus-use";
import { Usercentrics } from "./utils/usercentrics";
export default class default_1 extends ApplicationController {
    static targets = [
        "map",
        "cardSwiper",
        "showMapButton",
        "showImageButton",
        "mapLoader"
    ];
    static values = {
        geojsonUrl: String,
        accessToken: String,
        mapCenter: Array
    };
    map;
    geojson;
    isMapInitialized = false;
    hasMapAppeared = false;
    isSourceLoaded = false;
    connect() {
        useWindowResize(this);
        window.addEventListener("consent_changed", (e) => {
            if (e.detail && e.detail.event == "consent_status") {
                this.initActions();
            }
        });
        window.addEventListener("UC_UI_INITIALIZED", (e) => {
            this.initActions();
        });
    }
    initActions() {
        this.setConsentLayer();
        if (this.isMapInitialized) {
            this.map?.remove();
            this.map = null;
            this.isMapInitialized = false;
        }
        else if (this.hasMapAppeared) {
            this.initMap();
        }
    }
    setConsentLayer() {
        if (!this.hasConsent()) {
            this.hideMapLoader();
            if (this.hasMapTarget) {
                this.mapTarget.innerHTML = Usercentrics.getMapboxOverlay();
            }
        }
    }
    hasConsent() {
        return Usercentrics.hasConsent('mapbox');
    }
    // noinspection JSUnusedGlobalSymbols
    showMap(e) {
        e.stopPropagation();
        e.preventDefault();
        if (this.hasCardSwiperTarget) {
            const swiper = this.cardSwiperTarget.swiper;
            swiper?.slideTo(1);
        }
        if (this.hasShowImageButtonTarget) {
            this.showImageButtonTarget.focus();
        }
    }
    // noinspection JSUnusedGlobalSymbols
    showImage(e) {
        e.stopPropagation();
        e.preventDefault();
        if (this.hasCardSwiperTarget) {
            const swiper = this.cardSwiperTarget.swiper;
            swiper?.slideTo(0);
        }
        if (this.hasShowMapButtonTarget) {
            this.showMapButtonTarget.focus();
        }
    }
    // noinspection JSUnusedGlobalSymbols
    async initMap(e) {
        if (e && e.detail.activeIndex !== 1) {
            return;
        }
        this.hasMapAppeared = true;
        if (!(this.hasMapTarget && this.hasConsent())) {
            return;
        }
        if (!this.isMapInitialized) {
            this.showMapLoader();
            this.isMapInitialized = true;
            this.map = await this.initMapCore(this.mapTarget, false);
        }
    }
    async initMapCore(mapTarget, interactive) {
        const [{}, mapboxgl] = await Promise.all([
            // @ts-ignore
            import("mapbox-gl/dist/mapbox-gl.css"),
            import("mapbox-gl"),
        ]);
        let map = new mapboxgl.Map({
            accessToken: this.accessTokenValue,
            container: mapTarget, // container ID
            style: "mapbox://styles/umfulana/cl0c2flkh008c16mqtatiowt8",
            center: this.mapCenterValue || [0, 0],
            zoom: 3,
            renderWorldCopies: true,
            maxZoom: 12,
            attributionControl: false,
            interactive: interactive,
            antialias: true,
            maxPitch: 0,
            dragRotate: false
        });
        if (this.isSourceLoaded) {
            this.hideMapLoader();
            this.fitBounds(map, mapboxgl);
        }
        map.on('sourcedata', (e) => {
            if (!this.isSourceLoaded && e.sourceId === "tour" && e.isSourceLoaded) {
                this.isSourceLoaded = true;
                this.hideMapLoader();
                map.once('idle', (e) => {
                    this.fitBounds(map, mapboxgl);
                });
                let resizeTimeout = null;
                window.addEventListener("resize", () => {
                    if (resizeTimeout) {
                        clearTimeout(resizeTimeout);
                    }
                    resizeTimeout = setTimeout(() => {
                        this.fitBounds(map, mapboxgl);
                    }, 150);
                });
            }
        });
        const [{}, response] = await Promise.all([
            map.once("load"),
            fetch(this.geojsonUrlValue)
        ]);
        this.geojson = await (response ? response.json() : Promise.resolve(null));
        const layers = map.getStyle().layers || [];
        // Find the index of the first symbol layer in the map style.
        let firstSymbolId;
        for (const layer of layers) {
            if (layer.type === "symbol") {
                firstSymbolId = layer.id;
                break;
            }
        }
        map.addSource("tour", {
            "type": "geojson",
            "data": this.geojson
        });
        map.addLayer({
            id: "tour-icons",
            type: "symbol",
            source: "tour",
            layout: {
                "icon-image": "location-dot-solid-red",
                "icon-anchor": "bottom",
                "icon-size": 0.5,
                "icon-allow-overlap": true
            },
            "paint": {
                "text-color": "#700000",
                "text-halo-color": "#fff",
                "text-halo-width": 2
            },
            filter: ["==", "$type", "Point"]
        });
        map.addLayer({
            id: "tour-titles",
            type: "symbol",
            source: "tour",
            layout: {
                "text-field": ["get", "title"],
                "text-offset": [0, 0],
                "text-variable-anchor": ["top", "bottom", "left", "right"],
                "text-justify": "auto",
                "text-size": 10,
                "text-allow-overlap": false
            },
            "paint": {
                "text-color": "#700000",
                "text-halo-color": "#fff",
                "text-halo-width": 2
            },
            filter: ["==", "$type", "Point"]
        });
        map.addLayer({
            "id": "tour-route",
            "type": "line",
            "source": "tour",
            "layout": {
                "line-join": "round",
                "line-cap": "round"
            },
            "paint": {
                "line-color": "#9e0000",
                "line-width": 2,
                "line-opacity": 0.5
            },
            filter: ["==", "$type", "LineString"]
        }, firstSymbolId);
        return map;
    }
    fitBounds(map, mapboxgl) {
        if (!this.geojson) {
            return;
        }
        const features = this.geojson.features
            .filter((feature) => feature.geometry.type === "Point");
        // Create a 'LngLatBounds' with both corners at the first coordinate.
        const bounds = new mapboxgl.LngLatBounds(features[0].geometry.coordinates, features[0].geometry.coordinates);
        // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
        for (const feature of features) {
            bounds.extend(feature.geometry.coordinates);
        }
        map.fitBounds(bounds, {
            padding: 50
        });
        map.resize();
    }
    hideMapLoader() {
        if (this.hasMapLoaderTarget) {
            this.mapLoaderTarget.classList.add("hidden");
        }
    }
    showMapLoader() {
        if (this.hasMapLoaderTarget) {
            this.mapLoaderTarget.classList.remove("hidden");
        }
    }
}
