import { Controller } from '@hotwired/stimulus';
export default class default_1 extends Controller {
    static targets = ['tab', 'panel', 'select'];
    activeTabClasses;
    inactiveTabClasses;
    connect() {
        this.activeTabClasses = (this.data.get('activeTab') || 'active').split(' ');
        this.inactiveTabClasses = (this.data.get('inactiveTab') || 'inactive').split(' ');
        if (this.anchor)
            this.index = this.tabTargets.findIndex((tab) => tab.id === this.anchor);
        this.showTab();
    }
    change(event) {
        event.preventDefault();
        if (event.currentTarget.tagName === 'SELECT') {
            this.index = event.currentTarget.selectedIndex;
            // window.scrollTo({
            //     behavior: 'smooth',
            //     // @ts-ignore
            //     left: this.element.offsetLeft,
            //     // @ts-ignore
            //     top: this.element.offsetTop
            // })
        }
        else if (event.currentTarget.dataset.index) {
            // If target specifies an index, use that
            this.index = event.currentTarget.dataset.index;
        }
        else if (event.currentTarget.dataset.id) {
            // If target specifies an id, use that
            this.index = this.tabTargets.findIndex((tab) => tab.id == event.currentTarget.dataset.id);
        }
        else {
            // Otherwise, use the index of the current target
            this.index = this.tabTargets.indexOf(event.currentTarget);
        }
        // Select setzen
        if (this.hasSelectTarget) {
            this.selectTarget.selectedIndex = this.index;
        }
        document.dispatchEvent(new CustomEvent('tab-changed', {
            detail: {
                index: this.index
            }
        }));
    }
    showTab() {
        this.tabTargets.forEach((tab, index) => {
            const panel = this.panelTargets[index];
            if (index === this.index) {
                panel.classList.remove('hidden');
                tab.classList.remove(...this.inactiveTabClasses);
                tab.classList.add(...this.activeTabClasses);
                // Update URL with the tab ID if it has one
                // This will be automatically selected on page load
                if (tab.id) {
                    location.hash = tab.id;
                }
            }
            else {
                panel.classList.add('hidden');
                tab.classList.remove(...this.activeTabClasses);
                tab.classList.add(...this.inactiveTabClasses);
            }
        });
    }
    get index() {
        // @ts-ignore
        return parseInt(this.data.get('index') || 0);
    }
    set index(value) {
        // @ts-ignore
        this.data.set('index', (value >= 0 ? value : 0));
        this.showTab();
    }
    get anchor() {
        return (document.URL.split('#').length > 1) ? document.URL.split('#')[1] : null;
    }
}
