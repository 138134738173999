import { Controller } from '@hotwired/stimulus';
export default class default_1 extends Controller {
    static targets = [
        'card',
        'cardSwiper',
        'btnMap',
        'btnImage'
    ];
    isShowMaps = false;
    connect() {
        super.connect();
        this.initButtonVisibility();
    }
    initialize() {
        this.isShowMaps = window.sessionStorage?.getItem(this.storageId) === 'true';
    }
    initButtonVisibility() {
        if (this.hasBtnMapTarget && this.hasBtnImageTarget) {
            if (this.isShowMaps) {
                this.btnMapTarget.classList.add('hidden');
                this.btnImageTarget.classList.remove('hidden');
            }
            else {
                this.btnMapTarget.classList.remove('hidden');
                this.btnImageTarget.classList.add('hidden');
            }
        }
    }
    get storageId() {
        return `isTourCardsShowMaps_${window.location.pathname}`;
    }
    saveIsShowMaps(isShowMaps) {
        this.isShowMaps = isShowMaps;
        window.sessionStorage?.setItem(this.storageId, this.isShowMaps.toString());
    }
    // noinspection JSUnusedGlobalSymbols
    showMaps() {
        this.swipeToMaps();
        this.saveIsShowMaps(true);
        this.initButtonVisibility();
    }
    // noinspection JSUnusedGlobalSymbols
    showImages() {
        this.swipeToImages();
        this.saveIsShowMaps(false);
        this.initButtonVisibility();
    }
    swipeToMaps() {
        if (this.hasCardSwiperTarget) {
            this.cardSwiperTargets.forEach((cardSwiper) => {
                const swiper = cardSwiper.swiper;
                if (swiper) {
                    swiper.slideTo(1);
                }
            });
        }
    }
    swipeToImages() {
        if (this.hasCardSwiperTarget) {
            this.cardSwiperTargets.forEach((cardSwiper) => {
                const swiper = cardSwiper.swiper;
                if (swiper) {
                    swiper.slideTo(0);
                }
            });
        }
    }
    // noinspection JSUnusedGlobalSymbols
    initCardState() {
        setTimeout(() => {
            if (this.isShowMaps) {
                this.swipeToMaps();
            }
            else {
                this.swipeToImages();
            }
        }, 200);
    }
    // noinspection JSUnusedGlobalSymbols
    cardSwiperTargetConnected(e) {
        this.initCardState();
    }
}
