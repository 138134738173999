import { Controller } from "@hotwired/stimulus";
export default class default_1 extends Controller {
    observer;
    static targets = [
        'navbar',
        'navitem',
    ];
    connect() {
        this.observer = new IntersectionObserver(this.onIntersect.bind(this), {
            rootMargin: `-50%`,
        });
        this.navitemTargets.forEach((item) => this.observer.observe(item));
    }
    disconnect() {
        this.navitemTargets.forEach((item) => this.observer.unobserve(item));
    }
    onIntersect(entries) {
        if (!this.hasNavbarTarget) {
            return;
        }
        entries.forEach((entry) => {
            this.navbarTarget.querySelectorAll('nav a, ul li a').forEach(el => {
                el.removeAttribute('data-active');
            });
            if (entry.isIntersecting) {
                this.navbarTarget.querySelectorAll('nav a').forEach(el => {
                    el.removeAttribute('data-active');
                    if (el.getAttribute('href') === `#${entry.target.id}`) {
                        el.setAttribute('data-active', '');
                    }
                });
                this.navbarTarget.querySelectorAll('ul li a').forEach(el => {
                    el.removeAttribute('data-active');
                    if (el.getAttribute('href') === `#${entry.target.id}`) {
                        el.setAttribute('data-active', '');
                        // @ts-ignore
                        this.navbarTarget.querySelectorAll('button span')[0].innerText = el.innerText.trim();
                    }
                });
            }
        });
    }
}
