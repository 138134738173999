import { Controller } from "@hotwired/stimulus";
import { Usercentrics } from "./utils/usercentrics";
export default class default_1 extends Controller {
    // @ts-ignore
    element;
    static values = {
        name: String,
        source: String
    };
    connect() {
        window.addEventListener("consent_changed", (e) => {
            if (e.detail && e.detail.event == "consent_status") {
                this.setConsentLayer();
            }
        });
        window.addEventListener("UC_UI_INITIALIZED", (e) => {
            this.setConsentLayer();
        });
    }
    setConsentLayer() {
        if (Usercentrics.hasConsent(this.nameValue)) {
            const iframe = document.createElement('iframe');
            iframe.classList.add('absolute', 'inset-0', 'h-full', 'w-full', 'border-0');
            iframe.allowFullscreen = true;
            iframe.loading = 'lazy';
            iframe.frameBorder = '0';
            iframe.src = this.sourceValue;
            iframe.setAttribute('data-hj-allow-iframe', '');
            this.element.append(iframe);
        }
        else if (this.nameValue === 'youtube') {
            this.element.innerHTML = Usercentrics.getYouTubeOverlay();
        }
        else if (this.nameValue === 'googlemaps') {
            this.element.innerHTML = Usercentrics.getGoogleMapsOverlay();
        }
        else {
            this.element.innerHTML = '';
        }
    }
}
