// @ts-ignore
import { Dropdown } from "tailwindcss-stimulus-components";
export default class ButtonDropdown extends Dropdown {
    // <!--
    //                               Entering: ""
    //                                 From: "transform opacity-0 scale-95"
    //                                 To: ""
    //                               Leaving: ""
    //                                 From: "transform opacity-100 scale-100"
    //                                 To: ""
    //                             -->
    connect() {
        super.connect();
        this.visibleClass = 'transform opacity-100 scale-100';
        this.invisibleClass = 'transform opacity-0 scale-95';
        this.activeClass = '';
        this.enteringClass = 'transition ease-out duration-100';
        this.leavingClass = 'transition ease-in duration-75';
    }
}
