import { Controller } from '@hotwired/stimulus';
export default class extends Controller {
    toast;
    toastId = "clipboardToast";
    connect() {
    }
    copy(e) {
        let text = e.params.text;
        navigator.clipboard
            .writeText(text)
            .then(() => {
            this.showToast(text);
        });
    }
    showToast(text) {
        if (this.toast) {
            this.toast.remove();
        }
        const html = `<div id="${this.toastId}" class="fixed transition-transform z-toast bottom-0 left-1/2 translate-y-full -translate-x-1/2 duration-300 pb-6 md:pb-12">
<div class="py-2 px-6 rounded-md text-white font-medium whitespace-nowrap ring-3 ring-white/50 ring-offset-4 ring-offset-transparent bg-red-600">Der Wert „${text}“ wurde kopiert.</div>
</div>`;
        document.body.insertAdjacentHTML("beforeend", html);
        this.toast = document.querySelector(`#${this.toastId}`);
        setTimeout(_ => {
            this.toast?.classList.remove("translate-y-full");
        }, 150);
        setTimeout(_ => {
            this.toast?.remove();
        }, 3000);
    }
}
