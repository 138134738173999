import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
    connect() {
    }
    openSearch(e) {
        e.preventDefault();
        let btn = document.querySelector("[data-algolia-autocomplete-target] button");
        btn?.click();
    }
}
