import { Controller } from "@hotwired/stimulus";
import { useIntersection, useResize } from "stimulus-use";
export default class default_1 extends Controller {
    // @ts-ignore
    element;
    static targets = [
        "item"
    ];
    static values = {
        useIntersection: { type: Boolean, default: true }
    };
    isVideoLoaded = false;
    connect() {
        useResize(this);
        if ('IntersectionObserver' in window && this.hasItemTarget) {
            useIntersection(this, {
                element: this.itemTarget,
                threshold: 0.9
            });
        }
    }
    // noinspection JSUnusedGlobalSymbols
    itemTargetConnected() {
        this.isVideoLoaded = this.itemTarget.autoplay;
    }
    play() {
        if (this.hasItemTarget) {
            this.itemTarget?.play();
        }
    }
    pause(event) {
        event?.stopPropagation();
        if (this.hasItemTarget && !this.itemTarget.paused) {
            this.itemTarget.pause();
        }
    }
    // noinspection JSUnusedGlobalSymbols
    playPause() {
        if (this.hasItemTarget) {
            if (this.itemTarget.paused) {
                this.itemTarget.play();
            }
            else {
                this.itemTarget.pause();
            }
        }
    }
    // noinspection JSUnusedGlobalSymbols
    appear() {
        if (!this.isVideoLoaded) {
            this.isVideoLoaded = true;
            if (this.itemTarget.dataset.src) {
                this.itemTarget.src = this.itemTarget.dataset.src;
            }
            if (this.itemTarget.dataset.poster) {
                this.itemTarget.poster = this.itemTarget.dataset.poster;
            }
            for (let source in this.itemTarget.children) {
                let videoSource = this.itemTarget.children[source];
                if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
                    if (videoSource.dataset.src) {
                        videoSource.src = videoSource.dataset.src;
                    }
                    if (videoSource.dataset.poster) {
                        videoSource.poster = videoSource.dataset.poster;
                    }
                }
            }
        }
        if (this.useIntersectionValue && window.innerWidth < 768 && this.isTouchDevice()) {
            this.play();
        }
    }
    // noinspection JSUnusedGlobalSymbols
    disappear() {
        if (this.useIntersectionValue && window.innerWidth < 768 && this.isTouchDevice()) {
            this.pause();
        }
    }
    resize({ width }) {
        if (this.useIntersectionValue && width >= 768 && !this.isTouchDevice()) {
            this.pause();
        }
    }
    isTouchDevice() {
        return (("ontouchstart" in window) ||
            (navigator.maxTouchPoints > 0) ||
            // @ts-ignore
            (navigator.msMaxTouchPoints > 0));
    }
}
