import { Controller } from "@hotwired/stimulus";
export default class default_1 extends Controller {
    isOpen = false;
    static targets = ['content', 'button'];
    openAttr = "data-is-open";
    connect() {
        const innerContent = this.contentTarget.children[0];
        if (this.contentTarget?.hasAttribute(this.openAttr)) {
            innerContent?.style.setProperty("visibility", "visible");
            innerContent?.style.setProperty("height", "auto");
            innerContent?.classList.remove("overflow-hidden");
        }
        else {
            innerContent?.style.setProperty("visibility", "hidden");
            innerContent?.style.setProperty("height", "0px");
            innerContent?.classList.add("overflow-hidden");
        }
    }
    async toggle(event) {
        event.stopPropagation();
        event.preventDefault();
        const { default: gsap, Power1 } = await import("gsap");
        const innerContent = this.contentTarget.children[0];
        if (this.contentTarget?.hasAttribute(this.openAttr)) {
            // Close
            let tl = gsap.timeline({
                onStart: () => {
                    this.contentTarget.removeAttribute(this.openAttr);
                    innerContent?.classList.add('overflow-hidden');
                }
            });
            tl
                .to(innerContent, { height: 0, ease: Power1?.easeInOut, opacity: 0 }, 0)
                .to(innerContent, { visibility: "hidden" }, ">")
                .to(this.buttonTarget, { innerText: 'Mehr' }, 0);
        }
        else {
            // Open
            let tl = gsap.timeline({
                onStart: () => {
                    this.contentTarget.setAttribute(this.openAttr, "");
                },
                onComplete() {
                    innerContent?.classList.remove('overflow-hidden');
                }
            });
            tl
                .set(innerContent, { opacity: 0, visibility: "hidden" })
                .to(innerContent, {
                height: "auto",
                visibility: "visible",
                ease: Power1?.easeInOut,
                opacity: 1
            }, 0)
                .to(this.buttonTarget, { innerText: 'Weniger' }, 0);
        }
        this.buttonTarget.innerText = this.isOpen ? 'Weniger' : 'Mehr';
    }
}
