import { Controller } from "@hotwired/stimulus";
import { useIntersection } from "stimulus-use";
import instantsearch from 'instantsearch.js';
import { liteClient as algoliasearch } from 'algoliasearch/lite';
import { history } from "instantsearch.js/es/lib/routers";
(() => {
    let oldPushState = window.history.pushState;
    window.history.pushState = function pushState() {
        let ret = oldPushState.apply(this, arguments);
        window.dispatchEvent(new Event("pushstate"));
        window.dispatchEvent(new Event("locationchange"));
        return ret;
    };
    let oldReplaceState = window.history.replaceState;
    window.history.replaceState = function replaceState() {
        let ret = oldReplaceState.apply(this, arguments);
        window.dispatchEvent(new Event("replacestate"));
        window.dispatchEvent(new Event("locationchange"));
        return ret;
    };
    window.addEventListener("popstate", () => {
        window.dispatchEvent(new Event("locationchange"));
    });
})();
export default class default_1 extends Controller {
    search;
    static targets = [
        "facetFilter",
        "searchbox",
        "scrollTo",
        "hits",
        "infiniteHits",
        "pagination",
        "sorting",
        "currentRefinements",
        "clearRefinements",
        "breadcrumb",
        "statsButton",
        "activeFilters",
    ];
    static values = {
        baseUrlWithPath: String,
        appId: String,
        appKey: String,
        userToken: String,
        wishlistItems: Array,
        routingInfo: Object,
        indexPrefix: String,
        indexName: String,
        locale: String,
        hitsPerPage: { type: Number, default: 6 },
        currentPath: String,
        reiseplanungUrl: { type: String, default: "/reiseplanung" },
    };
    isAlgoliaInitialized = false;
    currentUrl = "";
    emptyString = "";
    searchboxPlaceholder = "";
    rootPath = "";
    urlPart = "";
    showMoreResults = "";
    showPreviousResults = "";
    showOneResult = "";
    showManyResults = "";
    encodedPages;
    connect() {
        if ('IntersectionObserver' in window) {
            useIntersection(this, {
                rootMargin: "100px 0px 0px 0px"
            });
        }
        switch (this.indexNameValue) {
            case "testimonials":
                this.emptyString = "Keine Reiseberichte gefunden!";
                this.searchboxPlaceholder = "Reiseberichte durchsuchen";
                this.rootPath = "Reiseberichte";
                this.urlPart = "reiseberichte";
                this.showMoreResults = 'Mehr Reiseberichte anzeigen';
                this.showPreviousResults = 'Vorherige Reiseberichte anzeigen';
                this.showOneResult = 'Reisebericht anzeigen';
                this.showManyResults = 'Reiseberichte anzeigen';
                break;
            case "blog":
                this.emptyString = "Keine Artikel gefunden!";
                this.searchboxPlaceholder = "Artikel durchsuchen";
                this.rootPath = "Reiseziele";
                this.urlPart = "blog";
                this.showMoreResults = 'Mehr Artikel anzeigen';
                this.showPreviousResults = 'Vorherige Artikel anzeigen';
                this.showOneResult = 'Artikel anzeigen';
                this.showManyResults = 'Artikel anzeigen';
                break;
            default:
                this.emptyString = "Keine Rundreisen gefunden!";
                this.searchboxPlaceholder = "Rundreise durchsuchen";
                this.rootPath = "Rundreisen";
                this.urlPart = "rundreisen";
                this.showMoreResults = 'Mehr Rundreisen anzeigen';
                this.showPreviousResults = 'Vorherige Rundreisen anzeigen';
                this.showOneResult = 'Rundreise anzeigen';
                this.showManyResults = 'Rundreisen anzeigen';
        }
        if (this.routingInfoValue && this.routingInfoValue.encodedPages) {
            this.encodedPages = {};
            Object.keys(this.routingInfoValue.encodedPages).forEach((key) => {
                const oldVal = this.routingInfoValue.encodedPages[key];
                if (oldVal !== this.rootPath) {
                    this.encodedPages[key] = `${this.rootPath} > ${oldVal}`;
                }
            });
        }
    }
    async initAlgolia() {
        if (this.isAlgoliaInitialized) {
            return Promise.resolve();
        }
        this.isAlgoliaInitialized = true;
        // Falls Algolia nicht innerhalb von 1 Sek. lädt, dann Blur-Effekt entfernen
        const to = setTimeout(() => {
            let el = null;
            if (this.hasHitsTarget) {
                el = this.hitsTarget.querySelector(".blur-md");
            }
            else if (this.hasInfiniteHitsTarget) {
                el = this.infiniteHitsTarget.querySelector(".blur-md");
            }
            if (el) {
                el.classList.remove("blur-md", "grayscale");
            }
            let dataSrcsets;
            let dataSources;
            if (this.hasHitsTarget) {
                dataSrcsets = this.hitsTarget.querySelectorAll("[data-srcset]") || [];
                dataSources = this.hitsTarget.querySelectorAll("[data-src]") || [];
            }
            else if (this.hasInfiniteHitsTarget) {
                dataSrcsets = this.infiniteHitsTarget.querySelectorAll("[data-srcset]") || [];
                dataSources = this.infiniteHitsTarget.querySelectorAll("[data-src]") || [];
            }
            dataSrcsets?.forEach((img) => {
                img.srcset = img.dataset.srcset;
                delete img.dataset.srcset;
            });
            dataSources?.forEach((img) => {
                img.src = img.dataset.src;
                delete img.dataset.src;
            });
            clearTimeout(to);
        }, 1000);
        const [{ default: aa }, { createInsightsMiddleware }] = await Promise.all([
            import("search-insights"),
            import("instantsearch.js/es/middlewares"),
            import("instantsearch.js/es/widgets")
        ]);
        if (this.userTokenValue) {
            aa('setUserToken', this.userTokenValue);
        }
        this.search = instantsearch({
            indexName: this.indexName,
            searchClient: algoliasearch(this.appIdValue, this.appKeyValue),
            numberLocale: this.localeValue,
            routing: this.getRouting(),
            // initialUiState: this.getInitialUiState(),
            future: {
                preserveSharedStateOnUnmount: false,
            },
            insights: {
                insightsClient: aa,
                insightsInitParams: {
                    // @ts-ignore
                    useCookie: true
                }
            },
        });
        const widgets = await this.getWidgets();
        this.search.addWidgets(widgets);
        this.search.start();
    }
    /**
     * @private
     *
     * Hier eine einfache Erklärung:
     * - stateToRoute: Nimmt den aktuellen Zustand deiner Suche (z.B. Suchbegriff "Apfel", Kategorie "Obst", sortiert nach "Preis") und wandelt ihn in URL-Parameter um (z.B. q=Apfel&category=Obst&sort=price). Denk an "Zustand zu Route" - der Zustand deiner Suche wird in die URL-Route übersetzt.
     * - createURL: Nimmt die von stateToRoute erzeugten Parameter und baut daraus eine vollständige URL (z.B. https://meineseite.de/suche?q=Apfel&category=Obst&sort=price). Im Grunde fügt es die Basis-URL zu den Parametern hinzu.
     * - parseURL: Macht das Gegenteil von stateToRoute. Es nimmt eine URL (z.B. https://meineseite.de/suche?q=Apfel&category=Obst&sort=price) und extrahiert die relevanten Parameter (z.B. q=Apfel, category=Obst, sort=price). Denk an "URL analysieren" - es zerlegt die URL in ihre Bestandteile.
     * - routeToState: Macht das Gegenteil von createURL und stateToRoute kombiniert. Es nimmt die von parseURL extrahierten Parameter und wandelt sie zurück in den Zustand deiner Suche (z.B. Suchbegriff "Apfel", Kategorie "Obst", sortiert nach "Preis"). Denk an "Route zu Zustand" - die URL-Route wird zurück in den Suchzustand übersetzt.
     *
     * Zusammenhang:
     * Diese Funktionen arbeiten zusammen, um eine bidirektionale Verbindung zwischen dem Zustand deiner Suche und der URL herzustellen.
     * Wenn der Suchzustand sich ändert, rufst du stateToRoute und dann createURL auf, um die URL zu aktualisieren.
     * Wenn die URL sich ändert (z.B. durch Klicken auf einen Link oder Zurück-Button), rufst du parseURL und dann routeToState auf, um den Suchzustand zu aktualisieren.
     */
    getRouting() {
        const indexName = this.indexName;
        const initialUiState = this.getInitialUiState();
        const currentPath = this.currentPathValue;
        const urlPart = this.urlPart;
        return {
            router: history({
                cleanUrlOnDispose: false,
                // Nimmt die von stateToRoute erzeugten Parameter und baut daraus eine vollständige URL
                // (z.B. https://meineseite.de/suche?q=Apfel&category=Obst&sort=price).
                // Im Grunde fügt es die Basis-URL zu den Parametern hinzu.
                createURL({ qsModule, routeState, location }) {
                    const regExp = new RegExp("/^(.*?)\\/" + urlPart + "/");
                    const urlParts = location.href.match(regExp);
                    const baseUrl = `${urlParts ? urlParts[1] || "" : ""}/`;
                    const queryParameters = {};
                    if (routeState.query) {
                        // @ts-ignore
                        queryParameters[`query`] = encodeURIComponent(routeState.query);
                    }
                    if (routeState.days) {
                        // @ts-ignore
                        queryParameters[`days`] = encodeURIComponent(routeState.days);
                    }
                    // @ts-ignore
                    if (routeState.page !== 1) {
                        queryParameters[`page`] = routeState.page;
                    }
                    [
                        'traveltime', 'transport', 'interest', 'special', 'duration', 'about', 'destination', 'region_highlight'
                    ].forEach((param) => {
                        if (routeState[param]) {
                            // @ts-ignore
                            queryParameters[param] = routeState[param].map(encodeURIComponent);
                        }
                    });
                    function removeMatchingArrays(obj1, obj2) {
                        const newObj = {};
                        for (const key in obj1) {
                            if (!obj2.hasOwnProperty(key) || !arraysEqual(obj1[key], obj2[key])) {
                                newObj[key] = obj1[key];
                            }
                        }
                        return newObj;
                    }
                    function arraysEqual(arr1, arr2) {
                        return arr1.length === arr2.length && arr1.every((value, index) => value === arr2[index]);
                    }
                    const queryString = qsModule.stringify(removeMatchingArrays(queryParameters, initialUiState[indexName]?.refinementList || {}), {
                        addQueryPrefix: true,
                        arrayFormat: 'repeat'
                    });
                    return `${window.location.origin}${currentPath}${queryString}`;
                },
                // Macht das Gegenteil von stateToRoute. Es nimmt eine URL (z.B. https://meineseite.de/suche?q=Apfel&category=Obst&sort=price)
                // und extrahiert die relevanten Parameter (z.B. q=Apfel, category=Obst, sort=price).
                // Denk an "URL analysieren" - es zerlegt die URL in ihre Bestandteile.
                parseURL({ qsModule, location }) {
                    const pathnameMatches = location.pathname.match(/search\/(.*?)\/?$/);
                    const { query = '', page, traveltime = [], transport = [], interest = [], special = [], duration = [], days = '', about = [], destination = [], region_highlight = [], } = qsModule.parse(location.search.slice(1));
                    const prepare = (value) => {
                        return (Array.isArray(value) ? value : [value])
                            .filter(Boolean)
                            .map(decodeURIComponent);
                    };
                    return {
                        query: decodeURIComponent(query),
                        page,
                        traveltime: prepare(traveltime),
                        transport: prepare(transport),
                        interest: prepare(interest),
                        special: prepare(special),
                        duration: prepare(duration),
                        days: decodeURIComponent(days),
                        about: prepare(about),
                        destination: prepare(destination),
                        region_highlight: prepare(region_highlight),
                    };
                }
            }),
            stateMapping: {
                // Nimmt den aktuellen Zustand deiner Suche (z.B. Suchbegriff "Apfel", Kategorie "Obst",
                // sortiert nach "Preis") und wandelt ihn in URL-Parameter um (z.B. q=Apfel&category=Obst&sort=price).
                // Denk an "Zustand zu Route" - der Zustand deiner Suche wird in die URL-Route übersetzt.
                stateToRoute(uiState) {
                    // @ts-ignore
                    const indexUiState = uiState[indexName] || {};
                    // console.log('stateToRoute', indexUiState);
                    return {
                        query: indexUiState.query,
                        page: indexUiState.page,
                        sortBy: indexUiState.sortBy,
                        interest: indexUiState.refinementList ? indexUiState.refinementList.interest : [],
                        special: indexUiState.refinementList ? indexUiState.refinementList.special : [],
                        transport: indexUiState.refinementList ? indexUiState.refinementList.transport : [],
                        destination: indexUiState.refinementList ? indexUiState.refinementList.destination : [],
                        region_highlight: indexUiState.refinementList ? indexUiState.refinementList.region_highlight : [],
                        traveltime: indexUiState.refinementList ? indexUiState.refinementList.traveltime : [],
                        about: indexUiState.refinementList ? indexUiState.refinementList.about : [],
                        duration: indexUiState.refinementList ? indexUiState.refinementList.duration : [],
                        days: indexUiState.range ? indexUiState.range.days : '',
                    };
                },
                // Macht das Gegenteil von createURL und stateToRoute kombiniert. Es nimmt die von parseURL
                // extrahierten Parameter und wandelt sie zurück in den Zustand deiner Suche
                // (z.B. Suchbegriff "Apfel", Kategorie "Obst", sortiert nach "Preis"). Denk an "Route zu Zustand" -
                // die URL-Route wird zurück in den Suchzustand übersetzt.
                routeToState(routeState) {
                    // console.log('routeToState', routeState);
                    const prepare = (key) => {
                        const arr1 = ((Array.isArray(routeState[key]) ? routeState[key] : [routeState[key]]) || []).filter(Boolean);
                        const refinementList = initialUiState[indexName]?.refinementList ? initialUiState[indexName]?.refinementList[key] : [];
                        const arr2 = (refinementList || []).filter(Boolean);
                        return arr1.concat(arr2) || [];
                    };
                    let state = {
                        query: routeState.query,
                        page: routeState.page,
                        refinementList: {
                            interest: prepare('interest'),
                            special: prepare('special'),
                            transport: prepare('transport'),
                            duration: prepare('duration'),
                            traveltime: prepare('traveltime'),
                            destination: prepare('destination'),
                            region_highlight: prepare('region_highlight'),
                            about: prepare('about'),
                        },
                        range: {
                            days: decodeURIComponent(routeState.days),
                        },
                    };
                    return {
                        [indexName]: state,
                    };
                }
            }
        };
    }
    disconnect() {
    }
    get indexName() {
        let indexName = this.indexNameValue === "blog" ? "pages_published_desc" : this.indexNameValue;
        return `${this.indexPrefixValue}_${indexName}`;
    }
    getInitialUiState() {
        let initialUiState = {};
        initialUiState[this.indexName] = {};
        if (this.hasRoutingInfoValue) {
            initialUiState[this.indexName] = this.routingInfoValue.initialUiState;
        }
        return initialUiState;
    }
    async getWidgets() {
        const { default: configure } = await import("instantsearch.js/es/widgets/configure/configure");
        const emptyString = this.emptyString;
        const showMoreResults = this.showMoreResults;
        const showPreviousResults = this.showPreviousResults;
        const indexNameValue = this.indexNameValue;
        let widgetParams = {
            hitsPerPage: this.hitsPerPageValue,
            distinct: true,
            filters: this.indexNameValue === "blog" ? "pageType:blog" : "",
            analyticsTags: ["instantsearch"]
        };
        if (this.routingInfoValue) {
            if (this.routingInfoValue.facetFilters) {
                widgetParams.facetFilters = this.routingInfoValue.facetFilters;
            }
            if (this.routingInfoValue.ruleContexts) {
                widgetParams.ruleContexts = this.routingInfoValue.ruleContexts;
            }
        }
        let widgets = [
            configure(widgetParams)
        ];
        const hitsTransformItems = (items) => {
            let newItems = items.map((item) => {
                const itemEl = document.createElement("div");
                itemEl.innerHTML = item.itemHtml;
                const isOnWishlist = this.wishlistItemsValue.some((wli) => {
                    return wli.tour && wli.tour.id === parseInt(item.objectID);
                }) ? "true" : "false";
                const wlsItems = itemEl
                    .querySelectorAll('[data-wishlist-selected-value="false"]');
                wlsItems?.forEach((el) => {
                    el.setAttribute("data-wishlist-selected-value", isOnWishlist);
                });
                item.itemHtml = itemEl.innerHTML;
                return item;
            });
            // wenn nicht vielfache von hitsPerPage
            if (indexNameValue === 'tours' && newItems.length % this.hitsPerPageValue !== 0) {
                newItems.push({
                    itemHtml: `<a href="${this.reiseplanungUrlValue}" class="relative group-card flex flex-col grow group/card min-h-96">
	<picture>

		<source srcset="/media/cache/w379-h213-c379x213/11071885.jpg.webp" media="(min-width: 1024px)" width="379"
				  height="213" type="image/webp">
		<source srcset="/media/cache/w460-h260-c460x260/11071885.jpg.webp" media="(min-width: 768px)" width="460"
				  height="260" type="image/webp">
		<source srcset="/media/cache/w704-h396-c704x396/11071885.jpg.webp" width="704" height="396" type="image/webp">

		<source srcset="/media/cache/w379-h213-c379x213/11071885.jpg" media="(min-width: 460px)" width="379" height="213">
		<source srcset="/media/cache/w460-h260-c460x260/11071885.jpg" media="(min-width: 768px)" width="1024"
				  height="260">

		<img src="/media/cache/w704-h396-c704x396/11071885.jpg" width="704" height="396" alt=""
			  class="object-cover object-center rounded-t-md">
	</picture>
	<div class="relative h-1.5">
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 760 1" class="w-full h-full absolute indent-0">
			<defs>
				<style>.c1 {
                fill: #333
            }

            .c2 {
                fill: #093
            }

            .c3 {
                fill: #9c9
            }

            .c4 {
                fill: #9cf
            }

            .c5 {
                fill: #39c
            }

            .c6 {
                fill: #fc3
            }

            .c7 {
                fill: #f93
            }

            .c8 {
                fill: #c00
            }</style>
			</defs>
			<polygon class="c1" points="801.89 -10 668.3 -9.91 717.94 17.09 801.91 17.03 801.89 -10"></polygon>
			<polygon class="c2" points="668.3 -9.91 610.65 -9.88 590.57 17.17 717.98 17.09 668.3 -9.91"></polygon>
			<polygon class="c3" points="544.28 -9.84 464.02 17.25 590.55 17.17 610.65 -9.88 544.28 -9.84"></polygon>
			<polygon class="c4" points="390.66 -9.74 433.41 17.27 464.05 17.25 544.28 -9.84 390.66 -9.74"></polygon>
			<polygon class="c5" points="390.66 -9.74 307.15 -9.69 290.73 17.36 433.38 17.27 390.66 -9.74"></polygon>
			<polygon class="c6" points="190.92 -9.61 231.6 17.39 290.73 17.36 307.15 -9.69 190.92 -9.61"></polygon>
			<polygon class="c7" points="190.92 -9.61 50.7 -9.53 129.36 17.46 231.63 17.39 190.92 -9.61"></polygon>
			<polygon class="c8" points="50.7 -9.53 -1.02 -9.49 -1 17.54 129.26 17.46 50.7 -9.53"></polygon>
		</svg>
	</div>
	<div class="relative w-full flex flex-col p-6">
		<div
			class="text-2xl font-medium text-red-600 text-balance leading-tight">Noch nicht das Passende gefunden?
		</div>
		<div class="leading-tight text-left font-light text-gray-500 mt-4">
			<span class="font-semibold">Kein Problem!</span> Wir helfen Ihnen bei der Planung Ihrer individuellen
			Rundreise. Dauer, Routen, Unterkünfte
			und Aktivitäten werden auf Ihre Bedürfnisse und Interessen abgestimmt. Erhalten Sie kostenfrei und
			unverbindlich
			Ihren persönlichen Reisevorschlag.
		</div>
		<div class="mt-6">
			<div class="btn-red">
				<svg class="size-5 shrink-0" fill="currentColor"
					  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
					<!--! Font Awesome Pro 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc. -->
					<path
						d="M471.6 99.4c46.8 45.1 72.2 109 68.3 174.7l-86.3-31.4c15.2-43.2 21.6-89 18.9-134.8c-.2-2.9-.5-5.7-.9-8.5zM402.8 54.3c21.7 10.1 36.3 31.4 37.7 55.6c2.5 41.4-3.3 82.8-17 121.9l-167.6-61c14.6-38.8 36.9-74.2 65.3-104.3c17.5-18.5 44.1-25.2 68.2-17.3c1.1 .4 2.2 .8 3.3 1.2c3.4 1.2 6.7 2.5 10 3.9zm-3.1-35.4c-109.8-38-228.4 3.2-292.6 94c-11.1 15.7-2.8 36.8 15.3 43.4l92.4 33.6 0 0L245 200.9l167.6 61 30.1 10.9 0 0 89.1 32.4c18.1 6.6 38-4.2 39.6-23.4c9-108.1-52-213.2-155.6-256.9c-2.4-1.1-4.9-2.1-7.4-3l-5.9-2.1c-.9-.3-1.8-.6-2.7-.9zM305.9 37c-2.7 2.3-5.4 4.8-7.9 7.5c-31.5 33.3-56 72.5-72.2 115.4l-89.6-32.6C176.5 73 239.2 40.1 305.9 37zM16 480c-8.8 0-16 7.2-16 16s7.2 16 16 16H560c8.8 0 16-7.2 16-16s-7.2-16-16-16H253.4l77.8-213.7-30.1-10.9L219.4 480H16z"></path>
				</svg>
				<span class="">Reiseplanung starten</span>
			</div>
		</div>
	</div>
</a>`,
                });
            }
            return newItems;
        };
        const hitsTemplates = {
            showMoreText(data, { html }) {
                return html `${showMoreResults}`;
            },
            showPreviousText(data, { html }) {
                return html `${showPreviousResults}`;
            },
            item(item, bindEvent) {
                const clickEvent = bindEvent("click", item, "Item clicked");
                const conversionEvent = bindEvent("conversion", item, "Item requested");
                item.itemHtml = (item.itemHtml || "")
                    .replaceAll(`<a href="/magazin/`, `<a ${clickEvent} href="/magazin/`)
                    .replaceAll(`<a href="/rundreise/`, `<a ${clickEvent} href="/rundreise/`)
                    .replaceAll(`<a href="/reisebericht/`, `<a ${clickEvent} href="/reisebericht/`)
                    .replaceAll(`<a href="/kontakt`, `<a ${conversionEvent} href="/kontakt`);
                return `${item.itemHtml}`;
            },
            empty(results, { html }) {
                return html `${emptyString}`;
            },
        };
        if (this.hasHitsTarget) {
            const { default: hits } = await import("instantsearch.js/es/widgets/hits/hits");
            widgets.push(hits({
                container: this.hitsTarget,
                cssClasses: {
                    list: "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10",
                    item: "flex flex-col shadow-md sm:hover:shadow-xl sm:transition-shadow rounded-md",
                    emptyRoot: "p-16 text-center text-2xl font-light text-gray-600"
                },
                escapeHTML: true,
                templates: hitsTemplates,
                transformItems: hitsTransformItems,
            }));
        }
        if (this.hasInfiniteHitsTarget) {
            const { default: infiniteHits } = await import("instantsearch.js/es/widgets/infinite-hits/infinite-hits");
            widgets.push(infiniteHits({
                showPrevious: true,
                container: this.infiniteHitsTarget,
                cssClasses: {
                    list: "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10",
                    item: "flex flex-col shadow-md sm:hover:shadow-xl sm:transition-shadow rounded-md",
                    emptyRoot: "p-16 text-center text-2xl font-light text-gray-600",
                    loadMore: "btn btn-gray mt-10 mx-auto py-1.5! px-4! w-full sm:w-auto",
                    disabledLoadMore: "hidden",
                    loadPrevious: "btn btn-gray mb-6 mx-auto py-1.5! px-4! w-full sm:w-auto",
                    disabledLoadPrevious: "hidden",
                },
                escapeHTML: true,
                templates: hitsTemplates,
                transformItems: hitsTransformItems
            }));
        }
        for (const facetFilter of this.facetFilterTargets) {
            switch (facetFilter.dataset.facet) {
                case 'destination':
                    widgets.push(await this.getRefinementListWidget(facetFilter, "destination", true));
                    break;
                case 'about':
                    widgets.push(await this.getRefinementListWidget(facetFilter, "about", false));
                    break;
                case 'traveltime':
                    let traveltimeWidget = await this.getTraveltimeWidget(facetFilter, "traveltime", false);
                    widgets.push(traveltimeWidget);
                    break;
                case 'duration':
                    widgets.push(await this.getRefinementListWidget(facetFilter, "duration", false));
                    break;
                case 'days':
                    widgets.push(await this.getRangeInputWidget(facetFilter, "days"));
                    break;
                case 'interest':
                    widgets.push(await this.getRefinementListWidget(facetFilter, "interest", false));
                    break;
                case 'region_highlight':
                    let regionHighlightWidget = await this.getRegionHighlightWidget(facetFilter, "region_highlight", false);
                    widgets.push(regionHighlightWidget);
                    break;
                case 'transport':
                    widgets.push(await this.getRefinementListWidget(facetFilter, "transport", false));
                    break;
                case 'special':
                    widgets.push(await this.getRefinementListWidget(facetFilter, "special", false));
                    break;
            }
            const clear = facetFilter.querySelector("[data-clear]") || null;
            if (clear) {
                const { default: clearRefinements } = await import("instantsearch.js/es/widgets/clear-refinements/clear-refinements");
                widgets.push(clearRefinements({
                    container: clear,
                    includedAttributes: [facetFilter.dataset.facet],
                    templates: {
                        resetLabel({ hasRefinements }, { html }) {
                            return html `Zurücksetzen`;
                        },
                    },
                    cssClasses: {
                        button: "btn btn-gray mt-2 py-1 w-full",
                        disabledButton: "opacity-50 cursor-not-allowed"
                    }
                }));
            }
        }
        if (this.hasSearchboxTarget) {
            const { default: searchBox } = await import("instantsearch.js/es/widgets/search-box/search-box");
            let timerId;
            let queriesSent = [];
            widgets.push(searchBox({
                container: this.searchboxTarget,
                showReset: false,
                placeholder: this.searchboxPlaceholder,
                showSubmit: false,
                showLoadingIndicator: false,
                cssClasses: {
                    input: "shadow-xs focus:ring-red-500 focus:border-red-500 block w-full text-base sm:text-xl border-gray-300 px-4 rounded-xl",
                },
                searchAsYouType: true,
                queryHook(query, refine) {
                    refine(query);
                    if (window._paq) {
                        clearTimeout(timerId);
                        timerId = setTimeout(() => {
                            if (query && queriesSent.indexOf(query) === -1) {
                                window._paq.push(["trackSiteSearch", query, false, 0]);
                                queriesSent.push(query);
                            }
                        }, 500);
                    }
                }
            }));
        }
        else {
            const { default: connectSearchBox } = await import("instantsearch.js/es/connectors/search-box/connectSearchBox");
            const virtualSearchBox = connectSearchBox(() => null);
            this.search.addWidgets([
                virtualSearchBox({ /* ... */})
            ]);
        }
        if (this.hasPaginationTarget) {
            const { default: pagination } = await import("instantsearch.js/es/widgets/pagination/pagination");
            widgets.push(pagination({
                container: this.paginationTarget,
                scrollTo: this.hasScrollToTarget ? this.scrollToTarget : this.hitsTarget,
                cssClasses: {
                    root: "px-4 flex items-center justify-center sm:px-6",
                    list: "relative z-0 inline-flex rounded-md shadow-xs -space-x-px",
                    item: "bg-white border-gray-300 text-gray-500 hover:bg-gray-100 relative hidden sm:inline-flex items-center border text-sm font-medium",
                    link: "px-4 py-2",
                    selectedItem: "z-10 border-red-600! text-red-600! font-bold! !hover:bg-gray-200",
                    previousPageItem: "inline-flex!",
                    nextPageItem: "inline-flex!",
                    firstPageItem: "inline-flex! rounded-l-lg",
                    lastPageItem: "inline-flex! rounded-r-lg",
                    disabledItem: "bg-white! border-gray-100! text-gray-300! !hover:bg-white"
                },
                templates: {
                    first(data, { html }) {
                        return html `
								<svg xmlns="http://www.w3.org/2000/svg" class="size-4" fill="none" viewBox="0 0 24 24"
									  stroke="currentColor">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
											d="M11 19l-7-7 7-7m8 14l-7-7 7-7"/>
								</svg>`;
                    },
                    previous(data, { html }) {
                        return html `
								<svg xmlns="http://www.w3.org/2000/svg" class="size-4" fill="none" viewBox="0 0 24 24"
									  stroke="currentColor">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"/>
								</svg>`;
                    },
                    next(data, { html }) {
                        return html `
								<svg xmlns="http://www.w3.org/2000/svg" class="size-4" fill="none" viewBox="0 0 24 24"
									  stroke="currentColor">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"/>
								</svg>`;
                    },
                    last(data, { html }) {
                        return html `
								<svg xmlns="http://www.w3.org/2000/svg" class="size-4" fill="none" viewBox="0 0 24 24"
									  stroke="currentColor">
									<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
											d="M13 5l7 7-7 7M5 5l7 7-7 7"/>
								</svg>`;
                    },
                }
            }));
        }
        if (this.hasSortingTarget) {
            const { default: sortBy } = await import("instantsearch.js/es/widgets/sort-by/sort-by");
            widgets.push(sortBy({
                container: this.sortingTarget,
                items: [
                    { label: "Empfohlen", value: `${this.indexName}` },
                    { label: "Preis (niedrigster zuerst)", value: `${this.indexName}_price_asc` },
                    { label: "Preis (höchster zuerst)", value: `${this.indexName}_price_desc` }
                ],
                cssClasses: {
                    select: "mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-hidden focus:ring-red-500 focus:border-red-500 sm:text-sm rounded-md"
                }
            }));
        }
        if (this.hasCurrentRefinementsTarget) {
            const { currentRefinements } = await import("instantsearch.js/es/widgets");
            const hasActiveFiltersTarget = this.hasActiveFiltersTarget;
            const activeFiltersTarget = this.hasActiveFiltersTarget ? this.activeFiltersTarget : null;
            const facetFilterTargets = this.facetFilterTargets;
            this.currentRefinementsTargets.forEach((container) => {
                const facets = this.facetFilterTargets;
                const excludedAttributes = this.facetFilterTargets
                    .filter((facetFilter) => facetFilter.classList.contains("hidden"))
                    .map((facetFilter) => facetFilter.dataset.facet);
                widgets.push(currentRefinements({
                    container,
                    excludedAttributes,
                    cssClasses: {
                        root: "grow",
                        list: "w-full",
                        label: "sr-only",
                        category: "inline-block ml-1 rounded-full border border-gray-200 py-1 pl-3 pr-2 bg-white mt-1",
                        item: "text-xs text-gray-700 inline-block",
                        delete: "ml-1 rounded-full text-gray-400 hover:bg-gray-200 hover:text-gray-500 size-4",
                    },
                    //@ts-ignore
                    transformItems: function (items, { results }) {
                        items = items
                            .sort((a, b) => {
                            const attributes = facetFilterTargets.map((facetFilter) => facetFilter.dataset.facet);
                            return attributes.indexOf(a.attribute) - attributes.indexOf(b.attribute);
                        });
                        const filteredItems = items.filter((item) => {
                            return (item.refinements || []).length > 0;
                        });
                        if (hasActiveFiltersTarget) {
                            if (filteredItems.length === 0) {
                                activeFiltersTarget?.classList.add("hidden");
                            }
                            else {
                                activeFiltersTarget?.classList.remove("hidden");
                            }
                        }
                        return filteredItems;
                    },
                }));
            });
        }
        if (this.hasClearRefinementsTarget) {
            const { default: clearRefinements } = await import("instantsearch.js/es/widgets/clear-refinements/clear-refinements");
            const excludedAttributes = this.facetFilterTargets
                .filter((facetFilter) => facetFilter.classList.contains("hidden"))
                .map((facetFilter) => facetFilter.dataset.facet);
            widgets.push(clearRefinements({
                container: this.clearRefinementsTarget,
                excludedAttributes,
                templates: {
                    resetLabel({ hasRefinements }, { html }) {
                        return html `<span>✕ Alle Filter zurücksetzen</span>`;
                    },
                },
                cssClasses: {
                    button: "ml-0.5 rounded-full text-gray-500 hover:text-gray-600 text-nowrap rounded-full hover:bg-gray-200 px-2 py-0.5",
                    disabledButton: "hidden"
                }
            }));
        }
        if (this.hasBreadcrumbTarget) {
            const { default: breadcrumb } = await import("instantsearch.js/es/widgets/breadcrumb/breadcrumb");
            widgets.push(breadcrumb({
                container: this.breadcrumbTarget,
                attributes: [
                    "hierarchicalPages.lvl0",
                    "hierarchicalPages.lvl1",
                    "hierarchicalPages.lvl2",
                    "hierarchicalPages.lvl3",
                    "hierarchicalPages.lvl4",
                    "hierarchicalPages.lvl5"
                ],
                templates: {
                    home: this.rootPath,
                    separator: ' / '
                },
                cssClasses: {
                    root: "flex",
                    list: "flex items-center",
                    selectedItem: "text-sm font-medium text-gray-400",
                    link: "text-sm font-medium text-gray-400 hover:text-gray-500",
                    separator: "text-sm mx-3 text-gray-400 font-extrabold"
                }
            }));
        }
        if (this.hasStatsButtonTarget) {
            const { default: stats } = await import("instantsearch.js/es/widgets/stats/stats");
            const showOneResult = this.showOneResult;
            const showManyResults = this.showManyResults;
            widgets.push(stats({
                container: this.statsButtonTarget,
                templates: {
                    text(data, { html }) {
                        return html `
								<span>${data.nbHits.toLocaleString('de-DE')} ${data.nbHits === 1 ? showOneResult : showManyResults}</span>`;
                    }
                }
            }));
        }
        return Promise.resolve(widgets);
    }
    async getHierarchicalMenuWidget(container, attributes, containerCount) {
        const { hierarchicalMenu } = await import("instantsearch.js/es/widgets");
        // @ts-ignore
        return hierarchicalMenu({
            container,
            attributes,
            rootPath: this.rootPath,
            cssClasses: {
                root: "",
                count: "hidden",
                searchableForm: "mt-1 mb-2",
                searchableInput: "shadow-xs focus:ring-red-500 focus:border-red-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md",
                searchableSubmit: "hidden",
                searchableReset: "hidden",
                selectedItem: "text-red-500",
                item: "text-base font-normal",
                childList: "ml-6"
            },
            limit: 99,
            sortBy: (a, b) => {
                return a.name.localeCompare(b.name, this.localeValue);
            },
            transformItems(items) {
                if (containerCount) {
                    const count = items.filter((v) => v.isRefined).length;
                    if (count) {
                        containerCount.classList.remove("hidden");
                        containerCount.innerText = count + "";
                    }
                    else {
                        containerCount.classList.add("hidden");
                    }
                }
                return items;
            },
            templates: {
                item(data, { html }) {
                    const cssLabel = (data.value.match(/ > /g) || []).length > 2 ? 'text-gray-500' : 'text-gray-900';
                    let checkboxClass = 'size-4 border border-gray-300 rounded-full focus:ring-red-500 flex items-center justify-center';
                    checkboxClass += data.isRefined ? ' bg-red-600' : '';
                    const active = data.isRefined ? html `
						<span class="w-1.5 h-1.5 bg-white rounded-full"></span>` : '';
                    return html `<a class="${data.cssClasses.link} block py-2.5 sm:py-1.5" href="${data.url}">
						<span class="flex items-center gap-x-2.5"><span class="${checkboxClass}">${active}</span><span
							class="${cssLabel} text-sm font-medium">${data.label}</span></span>
					</a>`;
                },
            }
        });
    }
    async getTraveltimeWidget(container, attribute, searchable = false) {
        const renderRefinementList = (renderOptions, isFirstRender) => {
            const { items, isFromSearch, refine, createURL, isShowingMore, canToggleShowMore, searchForItems, toggleShowMore, widgetParams, } = renderOptions;
            if (isFirstRender) {
                widgetParams.cssClasses.root
                    .split(' ')
                    .filter((cls) => cls)
                    .forEach((cls) => widgetParams.container.classList.add(cls));
                const ulSaison = document.createElement('ul');
                ulSaison.dataset.facet = 'saisons';
                'grid grid-cols-2 gap-2 mb-6'
                    .split(' ')
                    .forEach((cls) => ulSaison.classList.add(cls));
                widgetParams.container.appendChild(ulSaison);
                const ulMonths = document.createElement('ul');
                ulMonths.dataset.facet = 'traveltime';
                widgetParams.cssClasses.list
                    .split(' ')
                    .forEach((cls) => ulMonths.classList.add(cls));
                widgetParams.container.appendChild(ulMonths);
            }
            const months = [
                { short: "Jan", long: "Januar" },
                { short: "Feb", long: "Februar" },
                { short: "Mär", long: "März" },
                { short: "Apr", long: "April" },
                { short: "Mai", long: "Mai" },
                { short: "Jun", long: "Juni" },
                { short: "Jul", long: "Juli" },
                { short: "Aug", long: "August" },
                { short: "Sep", long: "September" },
                { short: "Okt", long: "Oktober" },
                { short: "Nov", long: "November" },
                { short: "Dez", long: "Dezember" },
            ];
            const getSaisonIcon = (saison, isActive) => {
                const iconCss = 'size-4 text-gray-500 shrink-0';
                const iconCssActive = 'size-4 text-red-600 shrink-0';
                switch (saison) {
                    case 'Frühling':
                        return `<svg xmlns="http://www.w3.org/2000/svg" class="${isActive ? iconCssActive : iconCss}" fill="currentColor" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M512 32c0 113.6-84.6 207.5-194.2 222c-7.1-53.4-30.6-101.6-65.3-139.3C290.8 46.3 364 0 448 0l32 0c17.7 0 32 14.3 32 32zM0 96C0 78.3 14.3 64 32 64l32 0c123.7 0 224 100.3 224 224l0 32 0 160c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-160C100.3 320 0 219.7 0 96z"/></svg>`;
                    case 'Sommer':
                        return `<svg xmlns="http://www.w3.org/2000/svg" class="${isActive ? iconCssActive : iconCss}" fill="currentColor" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M361.5 1.2c5 2.1 8.6 6.6 9.6 11.9L391 121l107.9 19.8c5.3 1 9.8 4.6 11.9 9.6s1.5 10.7-1.6 15.2L446.9 256l62.3 90.3c3.1 4.5 3.7 10.2 1.6 15.2s-6.6 8.6-11.9 9.6L391 391 371.1 498.9c-1 5.3-4.6 9.8-9.6 11.9s-10.7 1.5-15.2-1.6L256 446.9l-90.3 62.3c-4.5 3.1-10.2 3.7-15.2 1.6s-8.6-6.6-9.6-11.9L121 391 13.1 371.1c-5.3-1-9.8-4.6-11.9-9.6s-1.5-10.7 1.6-15.2L65.1 256 2.8 165.7c-3.1-4.5-3.7-10.2-1.6-15.2s6.6-8.6 11.9-9.6L121 121 140.9 13.1c1-5.3 4.6-9.8 9.6-11.9s10.7-1.5 15.2 1.6L256 65.1 346.3 2.8c4.5-3.1 10.2-3.7 15.2-1.6zM160 256a96 96 0 1 1 192 0 96 96 0 1 1 -192 0zm224 0a128 128 0 1 0 -256 0 128 128 0 1 0 256 0z"/></svg>`;
                    case 'Herbst':
                        return `<svg xmlns="http://www.w3.org/2000/svg" class="${isActive ? iconCssActive : iconCss}" fill="currentColor" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M272 96c-78.6 0-145.1 51.5-167.7 122.5c33.6-17 71.5-26.5 111.7-26.5l88 0c8.8 0 16 7.2 16 16s-7.2 16-16 16l-16 0-72 0s0 0 0 0c-16.6 0-32.7 1.9-48.3 5.4c-25.9 5.9-49.9 16.4-71.4 30.7c0 0 0 0 0 0C38.3 298.8 0 364.9 0 440l0 16c0 13.3 10.7 24 24 24s24-10.7 24-24l0-16c0-48.7 20.7-92.5 53.8-123.2C121.6 392.3 190.3 448 272 448l1 0c132.1-.7 239-130.9 239-291.4c0-42.6-7.5-83.1-21.1-119.6c-2.6-6.9-12.7-6.6-16.2-.1C455.9 72.1 418.7 96 376 96L272 96z"/></svg>`;
                    case 'Winter':
                        return `<svg xmlns="http://www.w3.org/2000/svg" class="${isActive ? iconCssActive : iconCss}" fill="currentColor" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M224 0c17.7 0 32 14.3 32 32l0 30.1 15-15c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-49 49 0 70.3 61.4-35.8 17.7-66.1c3.4-12.8 16.6-20.4 29.4-17s20.4 16.6 17 29.4l-5.2 19.3 23.6-13.8c15.3-8.9 34.9-3.7 43.8 11.5s3.8 34.9-11.5 43.8l-25.3 14.8 21.7 5.8c12.8 3.4 20.4 16.6 17 29.4s-16.6 20.4-29.4 17l-67.7-18.1L287.5 256l60.9 35.5 67.7-18.1c12.8-3.4 26 4.2 29.4 17s-4.2 26-17 29.4l-21.7 5.8 25.3 14.8c15.3 8.9 20.4 28.5 11.5 43.8s-28.5 20.4-43.8 11.5l-23.6-13.8 5.2 19.3c3.4 12.8-4.2 26-17 29.4s-26-4.2-29.4-17l-17.7-66.1L256 311.7l0 70.3 49 49c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-15-15 0 30.1c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-30.1-15 15c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l49-49 0-70.3-61.4 35.8-17.7 66.1c-3.4 12.8-16.6 20.4-29.4 17s-20.4-16.6-17-29.4l5.2-19.3L48.1 395.6c-15.3 8.9-34.9 3.7-43.8-11.5s-3.7-34.9 11.5-43.8l25.3-14.8-21.7-5.8c-12.8-3.4-20.4-16.6-17-29.4s16.6-20.4 29.4-17l67.7 18.1L160.5 256 99.6 220.5 31.9 238.6c-12.8 3.4-26-4.2-29.4-17s4.2-26 17-29.4l21.7-5.8L15.9 171.6C.6 162.7-4.5 143.1 4.4 127.9s28.5-20.4 43.8-11.5l23.6 13.8-5.2-19.3c-3.4-12.8 4.2-26 17-29.4s26 4.2 29.4 17l17.7 66.1L192 200.3l0-70.3L143 81c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l15 15L192 32c0-17.7 14.3-32 32-32z"/></svg>`;
                }
            };
            const saisons = ['Frühling', 'Sommer', 'Herbst', 'Winter'];
            widgetParams.container.querySelector(`ul[data-facet="saisons"]`).innerHTML = saisons
                .map((saison) => {
                const item = items.find((item) => item.label === saison);
                const icon = getSaisonIcon(saison, item?.isRefined);
                return `<li>
${item ? `<a
	class="border hover:bg-gray-100 has-checked:border-red-600 focus:border-red-600 flex items-center rounded-md py-1 font-medium ${item?.isRefined ? 'border-red-600' : 'border-gray-300'}"
				href="${createURL(item?.value)}"
				data-value="${item.value}">
		<span class="${widgetParams.cssClasses.labelText} ${item?.isRefined ? 'text-red-700!' : ''}">${icon}${saison}</span>
	</a>` : `<div
	class="border border-gray-200 flex items-center rounded-md py-1 font-medium bg-gray-100">
		<span class="${widgetParams.cssClasses.labelText} text-gray-400!">${icon}${saison}</span>
	</div>`}
</li>
			`;
            })
                .join('');
            widgetParams.container.querySelector(`ul[data-facet="traveltime"]`).innerHTML = months
                .map((month) => {
                const item = items.find((item) => item.label === month.long);
                return `<li>
${item ? `<a
	class="border hover:bg-gray-100 focus:border-red-600 flex items-center rounded-md py-1 font-medium ${item?.isRefined ? 'border-red-600' : 'border-gray-300'}"
            href="${createURL(item?.value)}"
            data-value="${item.value}">
		<span class="${widgetParams.cssClasses.labelText} ${item?.isRefined ? 'text-red-700!' : ''}">${month.short}</span>
	</a>` : `<div
	class="border border-gray-200 flex items-center rounded-md py-1 font-medium bg-gray-100">
		<span class="${widgetParams.cssClasses.labelText} text-gray-400!">${month.short}</span>
	</div>`}
</li>
			`;
            })
                .join('');
            [...widgetParams.container.querySelectorAll('a')].forEach(element => {
                element.addEventListener('click', (event) => {
                    event.preventDefault();
                    refine(event.currentTarget.dataset.value);
                });
            });
        };
        // 2. Create the custom widget
        const { connectRefinementList } = await import("instantsearch.js/es/connectors");
        const customRefinementList = connectRefinementList(renderRefinementList);
        // 3. Instantiate
        const filter = container.querySelector("[data-filter]") || null;
        const containerCount = container.querySelector("[data-count]") || null;
        const transformWidgetItemsFn = this.transformWidgetItems;
        return customRefinementList({
            container: filter,
            attribute,
            limit: 99,
            showMore: false,
            operator: "and",
            cssClasses: {
                root: "sm:px-4 sm:pt-4",
                labelText: "px-6 pt-0.5 text-sm font-medium text-gray-900 flex items-center justify-center gap-x-2 w-full",
                count: "hidden",
                searchableForm: "mt-1 mb-2",
                searchableInput: "shadow-xs focus:ring-red-500 focus:border-red-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md",
                searchableSubmit: "hidden",
                searchableReset: "hidden",
                selectedItem: "text-red-500! font-medium!",
                showMore: 'btn btn-red mt-4 py-1 w-full',
                checkbox: "hidden",
                item: "border border-gray-300 hover:bg-gray-100 has-checked:border-red-600 focus:border-red-600 flex items-center rounded-md py-1",
                list: "grid grid-cols-3 gap-2 w-full sm:w-max mb-4",
            },
            transformItems(items) {
                return transformWidgetItemsFn(items, containerCount, container);
            }
        });
    }
    async getRegionHighlightWidget(container, attribute, searchable = false) {
        const renderRefinementList = (renderOptions, isFirstRender) => {
            const { items, widgetParams, refine } = renderOptions;
            const groupedItems = items
                .reduce((acc, item) => {
                const match = item.value.match(/\((.*?)\)/);
                if (match) {
                    const country = match[1];
                    item.label = item.label.replace(match[0], "").trim();
                    if (!acc[country]) {
                        acc[country] = [];
                    }
                    acc[country].push(item);
                }
                return acc;
            }, {});
            const groupedItemsArray = Object.entries(groupedItems).map(([country, places]) => ({ country, places }));
            groupedItemsArray.sort((a, b) => {
                const countryComparison = a.country.localeCompare(b.country);
                if (countryComparison !== 0) {
                    return countryComparison;
                }
                else {
                    // If countries are the same, sort by places
                    return a.places[0].localeCompare(b.places[0]);
                }
            });
            const screentype = container.dataset.screentype || 'mobile';
            widgetParams.container.innerHTML = `<div class="ais-RefinementList overflow-hidden space-y-2 sm:max-h-96 sm:overflow-y-auto">${groupedItemsArray.map((item) => {
                const places = item.places.sort((a, b) => a.value.localeCompare(b.value));
                let html = `<div class="">`;
                html += `<div class="text-sm text-gray-400 uppercase pb-1 pt-2 px-3 font-semibold tracking-wide">${item.country}</div>`;
                html += `<ul class="ais-RefinementList-list" id="${screentype}-filter-${attribute}" role="menu" aria-labelledby="${screentype}-filter-btn-${attribute}">`;
                html += places.map((place) => {
                    return `<li role="presentation" class="ais-RefinementList-item flex items-center py-2 hover:bg-gray-100 px-4"><div><label class="ais-RefinementList-label"><input type="checkbox" class="ais-RefinementList-checkbox size-4 border-gray-300! rounded-sm text-red-600! focus:ring-red-500! checked:appearance-auto! checked:accent-red-600!" value="${place.value}" ${place.isRefined ? 'checked' : ''}><span role="menuitem" class="ais-RefinementList-labelText ml-3 pr-6 text-sm font-medium text-gray-900">${place.label}</span></label></div></li>`;
                }).join('');
                html += '</ul>';
                html += '</div>';
                return html;
            }).join('')}`;
            [...widgetParams.container.querySelectorAll('input')].forEach((element) => {
                element.addEventListener('change', (event) => {
                    if (event.currentTarget) {
                        event.preventDefault();
                        refine(event.currentTarget?.value);
                    }
                });
            });
        };
        // 2. Create the custom widget
        const { connectRefinementList } = await import("instantsearch.js/es/connectors");
        const customRefinementList = connectRefinementList(renderRefinementList);
        // 3. Instantiate
        const filter = container.querySelector("[data-filter]") || null;
        const containerCount = container.querySelector("[data-count]") || null;
        const transformWidgetItemsFn = this.transformWidgetItems;
        return customRefinementList({
            container: filter,
            attribute,
            limit: 99,
            showMore: false,
            operator: "and",
            cssClasses: {
                root: "sm:px-4 sm:pt-4",
                labelText: "px-6 pt-0.5 text-sm font-medium text-gray-900 flex items-center justify-center gap-x-2 w-full",
                count: "hidden",
                searchableForm: "mt-1 mb-2",
                searchableInput: "shadow-xs focus:ring-red-500 focus:border-red-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md",
                searchableSubmit: "hidden",
                searchableReset: "hidden",
                selectedItem: "text-red-500! font-medium!",
                showMore: 'btn btn-red mt-4 py-1 w-full',
                checkbox: "hidden",
                item: "border border-gray-300 hover:bg-gray-100 has-checked:border-red-600 focus:border-red-600 flex items-center rounded-md py-1",
                list: "grid grid-cols-3 gap-2 w-full sm:w-max mb-4",
            },
            transformItems(items) {
                return transformWidgetItemsFn(items, containerCount, container);
            }
        });
    }
    transformWidgetItems(items, containerCount, container) {
        const count = items.filter((v) => v.isRefined).length;
        if (containerCount) {
            if (count) {
                containerCount.classList.remove("hidden");
                containerCount.innerText = count + "";
            }
            else {
                containerCount.classList.add("hidden");
            }
        }
        if (container) {
            const button = container.querySelector("button");
            const icon = container.querySelector("button svg");
            if (items.length) {
                container.classList.remove("opacity-50");
                button?.removeAttribute("disabled");
                button?.classList.add("hover:text-gray-900");
                icon?.classList.add("group-hover:text-gray-500");
            }
            else {
                container.classList.add("opacity-50");
                button?.setAttribute("disabled", "disabled");
                button?.classList.remove("hover:text-gray-900");
                icon?.classList.remove("group-hover:text-gray-500");
            }
            if (count) {
                button?.classList.add("md:border-red-600");
                button?.classList.add("md:border-b-2");
            }
            else {
                button?.classList.remove("md:border-red-600");
                button?.classList.remove("md:border-b-2");
            }
        }
        return items;
    }
    async getRefinementListWidget(container, attribute, searchable = false, operator = "or") {
        const { refinementList } = await import("instantsearch.js/es/widgets");
        const filter = container.querySelector("[data-filter]") || null;
        const containerCount = container.querySelector("[data-count]") || null;
        let sortBy = ["name:asc"];
        let limit = 999;
        let cssClasses = {
            root: "overflow-hidden",
            checkbox: "size-4 border-gray-300! rounded-sm text-red-600! focus:ring-red-500! checked:appearance-auto! checked:accent-red-600!",
            list: "sm:max-h-96 sm:overflow-y-auto mb-2 mt-2",
            item: "flex items-center py-2 hover:bg-gray-100 px-4",
            labelText: "ml-3 pr-6 text-sm font-medium text-gray-900",
            count: "hidden",
            searchableForm: "",
            searchableInput: "shadow-xs focus:ring-0 focus:border-b-red-600 block w-full pr-12 sm:text-sm border-b border-b-gray-300 border-x-0! border-t-0! rounded-t-md",
            searchableSubmit: "hidden",
            searchableReset: "hidden",
            selectedItem: "text-red-500! font-medium!",
            showMore: 'btn btn-red mt-4 py-1 w-full'
        };
        if (attribute === "duration") {
            sortBy = (a, b) => {
                const months = [
                    "bis 7 Tage",
                    "8 bis 14 Tage",
                    "15 bis 21 Tage",
                    "22 bis 28 Tage",
                    "mehr als 28 Tage"
                ];
                return months.indexOf(a.name) - months.indexOf(b.name);
            };
        }
        else {
            sortBy = (a, b) => {
                return a.name.localeCompare(b.name, this.localeValue);
            };
        }
        const transformWidgetItemsFn = this.transformWidgetItems;
        return refinementList({
            container: filter,
            attribute,
            sortBy,
            cssClasses,
            searchable: searchable,
            searchablePlaceholder: "Suchen ...",
            operator,
            limit,
            showMore: false,
            showMoreLimit: limit * 10,
            templates: {
                showMoreText(data, { html }) {
                    return html `Mehr anzeigen`;
                },
            },
            transformItems(items) {
                return transformWidgetItemsFn(items, containerCount, container);
            }
        });
    }
    async getRangeInputWidget(container, attribute) {
        // Create the render function
        const renderRangeInput = (renderOptions, isFirstRender) => {
            const { start, range, refine, widgetParams } = renderOptions;
            const [min, max] = start;
            if (isFirstRender) {
                const form = document.createElement('form');
                form.classList.add('sm:p-4', 'flex', 'gap-x-2', 'items-center');
                form.addEventListener('submit', (event) => {
                    event.preventDefault();
                    const rawMinInputValue = parseFloat(event.target.elements.min.value);
                    const rawMaxInputValue = parseFloat(event.target.elements.max.value);
                    // Close dropdown if it exists and min-width of screen is minimal 640px
                    if (window.innerWidth >= 640) {
                        const dropdownController = this.application.getControllerForElementAndIdentifier(container, 'dropdown');
                        if (dropdownController) {
                            dropdownController.toggle();
                        }
                    }
                    refine([
                        Number.isFinite(rawMinInputValue) ? rawMinInputValue : undefined,
                        Number.isFinite(rawMaxInputValue) ? rawMaxInputValue : undefined,
                    ]);
                });
                widgetParams.container.appendChild(form);
                return;
            }
            widgetParams.container.querySelector('form').innerHTML = `
    <input
      type="number"
      name="min"
      step="1"
      min="${range.min}"
      max="${range.max}"
      placeholder="${range.min}"
      class="${widgetParams.cssClasses.input} ${widgetParams.cssClasses.inputMin}"
      value="${Number.isFinite(min) ? min : ''}"
    />
    <span>bis</span>
    <input
      type="number"
      name="max"
      step="1"
      min="${range.min}"
      max="${range.max}"
      placeholder="${range.max}"
      class="${widgetParams.cssClasses.input} ${widgetParams.cssClasses.inputMax}"
      value="${Number.isFinite(max) ? max : ''}"
    />
    <span>Tage</span>
    <button type="submit" class="${widgetParams.cssClasses.submit}">
    	Filtern
    </button>
  `;
        };
        // Create the custom widget
        const { connectRange } = await import("instantsearch.js/es/connectors");
        const customRangeInput = connectRange(renderRangeInput);
        // Instantiate the custom widget
        const filter = container.querySelector("[data-filter]") || null;
        let cssClasses = {
            root: "overflow-hidden",
            form: "sm:p-4 flex gap-x-2 items-center",
            label: "",
            input: "rounded-md px-2 py-1 [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-outer-spin-button]:m-0 [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-inner-spin-button]:m-0 focus:ring-red-600 focus:ring-offset-2 focus:ring-2 w-12",
            inputMin: "",
            inputMax: "",
            separator: "",
            submit: "btn btn-red py-1.5!",
        };
        return customRangeInput({
            container: filter,
            attribute: attribute,
            cssClasses,
        });
    }
    // noinspection JSUnusedGlobalSymbols
    async appear() {
        await this.initAlgolia();
    }
}
