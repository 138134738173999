import { Controller } from "@hotwired/stimulus";
export default class default_1 extends Controller {
    static targets = [
        "card",
        "collapse",
        "expand"
    ];
    static values = {
        isAllExpanded: { type: Boolean, default: false }
    };
    openAttr = "data-is-open";
    openByDefaultAttr = "data-open-by-default";
    async connect() {
        await this.initCards();
    }
    // noinspection JSUnusedGlobalSymbols
    async collapseAll() {
        this.cardTargets
            .filter((cardTarget) => cardTarget.hasAttribute(this.openAttr))
            .forEach((cardTarget) => {
            this.closeCard(cardTarget);
        });
        this.isAllExpandedValue = false;
        if (this.hasCollapseTarget) {
            this.collapseTarget.classList.add("hidden");
        }
        if (this.hasExpandTarget) {
            this.expandTarget.classList.remove("hidden");
        }
    }
    // noinspection JSUnusedGlobalSymbols
    async expandAll() {
        this.cardTargets
            .filter((cardTarget) => !cardTarget.hasAttribute(this.openAttr))
            .forEach((cardTarget) => {
            this.openCard(cardTarget, false);
        });
        this.isAllExpandedValue = true;
        if (this.hasCollapseTarget) {
            this.collapseTarget.classList.remove("hidden");
        }
        if (this.hasExpandTarget) {
            this.expandTarget.classList.add("hidden");
        }
    }
    getHeader(cardTarget) {
        if (cardTarget.querySelector("[data-icon-container]")) {
            return cardTarget.querySelector("[data-icon-container]");
        }
        return cardTarget.children[0];
    }
    getDetails(cardTarget) {
        return cardTarget.children[1];
    }
    getIcon(cardTarget) {
        let svgs = this.getHeader(cardTarget)?.querySelectorAll("svg");
        return (svgs?.length ? svgs[svgs.length - 1] : null);
    }
    async initCards() {
        this.cardTargets.forEach((cardTarget) => {
            const detailsTarget = this.getDetails(cardTarget);
            const iconTarget = this.getIcon(cardTarget);
            if (cardTarget?.hasAttribute(this.openAttr)) {
                detailsTarget?.style.setProperty("visibility", "visible");
                detailsTarget?.style.setProperty("height", "auto");
                detailsTarget?.classList.remove("overflow-hidden");
                iconTarget?.style.setProperty("transform", "scale(1, -1)");
            }
            else {
                detailsTarget?.style.setProperty("visibility", "hidden");
                detailsTarget?.style.setProperty("height", "0px");
                detailsTarget?.classList.add("overflow-hidden");
                iconTarget?.style.setProperty("transform", "scale(1, 1)");
            }
            this.getHeader(cardTarget)?.addEventListener("click", _ => {
                this.toggle(cardTarget, !(cardTarget.dataset.scrollIntoView === undefined));
            });
        });
    }
    async toggle(cardTarget, scrollIntoView) {
        if (cardTarget.hasAttribute(this.openAttr)) {
            await this.closeCard(cardTarget);
        }
        else {
            await this.openCard(cardTarget, scrollIntoView);
        }
    }
    async openCard(cardTarget, scrollIntoView) {
        const { default: gsap, Power1 } = await import("gsap");
        const detailsTarget = this.getDetails(cardTarget);
        const iconTarget = this.getIcon(cardTarget);
        let tl = gsap.timeline({
            onStart: () => {
                cardTarget.setAttribute(this.openAttr, "");
            },
            onComplete() {
                detailsTarget?.classList.remove('overflow-hidden');
                if (scrollIntoView) {
                    cardTarget?.scrollIntoView({ behavior: "smooth", block: "start" });
                }
            },
        });
        tl
            .set(detailsTarget, { opacity: 0, visibility: "hidden" })
            .to(detailsTarget, {
            height: "auto",
            visibility: "visible",
            ease: Power1?.easeInOut,
            opacity: 1
        }, 0)
            .to(iconTarget, { scaleY: -1 }, 0);
    }
    async closeCard(cardTarget) {
        const { default: gsap, Power1 } = await import("gsap");
        const detailsTarget = this.getDetails(cardTarget);
        const iconTarget = this.getIcon(cardTarget);
        let tl = gsap.timeline({
            onStart: () => {
                cardTarget.removeAttribute(this.openAttr);
                detailsTarget?.classList.add('overflow-hidden');
            }
        });
        tl
            .to(detailsTarget, { height: 0, ease: Power1?.easeInOut, opacity: 0 }, 0)
            .to(iconTarget, { scaleY: 1 }, 0)
            .to(detailsTarget, { visibility: "hidden" }, ">");
    }
}
