import { Controller } from "@hotwired/stimulus";
export default class default_1 extends Controller {
    static targets = [
        "button"
    ];
    static values = {
        url: String,
        title: String
    };
    connect() {
    }
    share(e) {
        if ("share" in navigator) {
            e.preventDefault();
            navigator.share({
                title: this.hasTitleValue ? this.titleValue : document.querySelector("title")?.innerText,
                url: this.hasUrlValue ? this.urlValue : window.location.href
            })
                .then(() => {
            })
                .catch(console.error);
        }
        else {
            const dropdownController = this.application.getControllerForElementAndIdentifier(this.element, "dropdown");
            dropdownController?.toggle(e);
        }
        if (window._paq) {
            window._paq.push([
                "trackContentInteraction",
                "click",
                "Teilen",
                this.hasTitleValue ? this.titleValue : document.querySelector("title")?.innerText,
                this.hasUrlValue ? this.urlValue : window.location.href
            ]);
        }
    }
}
