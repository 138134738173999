export default class MapboxGLButtonControl {
    _map;
    _btn;
    _container;
    _className = '';
    _title = '';
    _eventHandler;
    constructor({ className = "", title = "", eventHandler = (e) => {
    } }) {
        this._className = className;
        this._title = title;
        this._eventHandler = eventHandler;
    }
    onAdd(map) {
        this._btn = document.createElement("button");
        if (this._className) {
            this._btn.className = "mapboxgl-ctrl-icon" + " " + this._className;
        }
        else if (this._title) {
            this._btn.className = 'flex! items-center justify-center';
            this._btn.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" class="size-5 text-gray-800" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">\n' +
                '  <path stroke-linecap="round" stroke-linejoin="round" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />\n' +
                '</svg>';
        }
        this._btn.type = "button";
        this._btn.title = this._title;
        this._btn.onclick = this._eventHandler;
        this._container = document.createElement("div");
        this._container.className = "mapboxgl-ctrl-group mapboxgl-ctrl";
        this._container.appendChild(this._btn);
        return this._container;
    }
    onRemove() {
        this._container?.parentNode?.removeChild(this._container);
        this._map = undefined;
    }
}
