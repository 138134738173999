/** @jsx h */
import { Controller } from "@hotwired/stimulus";
import { useIntersection } from "stimulus-use";
export default class default_1 extends Controller {
    static targets = [
        "items"
    ];
    static values = {
        appId: String,
        appKey: String,
        indexPrefix: String,
        indexName: String,
        objectIds: Array,
        userToken: String,
        wishlistItems: Array,
        destination: String,
        kontinent: String,
        pageType: String,
    };
    isAlgoliaInitialized = false;
    initialize() {
    }
    connect() {
        if ('IntersectionObserver' in window) {
            useIntersection(this);
        }
    }
    async initAlgolia() {
        const [{ liteClient: algoliasearch }, { default: aa }, { default: instantsearch }, { configure }] = await Promise.all([
            import("algoliasearch/lite"),
            import("search-insights"),
            import("instantsearch.js"),
            import("instantsearch.js/es/widgets")
        ]);
        if (this.hasItemsTarget) {
            if (this.isAlgoliaInitialized) {
                return;
            }
            this.isAlgoliaInitialized = true;
            if (this.userTokenValue) {
                aa('setUserToken', this.userTokenValue);
            }
            let search = instantsearch({
                indexName: this.indexName,
                searchClient: algoliasearch(this.appIdValue, this.appKeyValue),
                future: {
                    preserveSharedStateOnUnmount: false,
                },
                insights: {
                    insightsClient: aa,
                    insightsInitParams: {
                        // @ts-ignore
                        useCookie: true,
                    },
                },
            });
            let widgets = [
                configure({
                    distinct: true,
                    analyticsTags: ["instantsearch"],
                })
            ];
            widgets.push(await this.getRelatedProductsWidget());
            search.addWidgets(widgets);
            search.start();
        }
    }
    // noinspection JSUnusedGlobalSymbols
    appear() {
        this.initAlgolia();
    }
    get indexName() {
        return `${this.indexPrefixValue}_${this.indexNameValue}`;
    }
    async getRelatedProductsWidget() {
        const { relatedProducts } = await import("instantsearch.js/es/widgets");
        const currentObjectIDs = this.objectIdsValue.map(value => value + "");
        const title = this.getTitle();
        const limit = 3;
        let props = {
            container: this.itemsTarget,
            objectIDs: currentObjectIDs,
            limit,
            cssClasses: {
                title: "text-2xl font-extrabold text-gray-700 sm:text-3xl lg:text-4xl text-center pb-12",
                list: "snap-x md:snap-none snap-mandatory flex md:grid md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8 lg:gap-12 overflow-x-scroll scrollbar-none md:overflow-x-visible lg:px-12 pb-2 px-1.5",
                item: "flex-none justify-end w-11/12 md:w-full md:flex md:flex-col md:justify-start snap-center md:snap-align-none shadow-md rounded-md overflow-visible",
                container: "",
                root: ""
            },
            escapeHTML: true,
            templates: {
                header: ({ cssClasses }, { html }) => {
                    return html `
						<h3 class=${cssClasses.title}>${title}</h3>`;
                },
                item(item, { html }) {
                    return html `${item.itemHtml}`;
                },
                empty(_, { html }) {
                    return '';
                },
            },
            transformItems: (items) => {
                return items
                    .slice(0, limit)
                    .map((item) => {
                    const isOnWishlist = this.wishlistItemsValue.some((wli) => {
                        return wli.tour && wli.tour.id === parseInt(item.objectID);
                    }) ? "true" : "false";
                    item.itemHtml = (item.itemHtml || "")
                        .replaceAll("data-wishlist-selected-value=\"false\"", `data-wishlist-selected-value="${isOnWishlist}"`);
                    return item;
                });
            },
            fallbackParameters: this.getFallbackParameters(),
            queryParameters: this.getQueryParameters(),
        };
        return relatedProducts(props);
    }
    getTitle() {
        switch (this.indexNameValue) {
            case 'tours':
                return "Ähnliche Rundreisen";
            case 'testimonials':
                return "Ähnliche Reiseberichte";
            case 'pages':
                return 'Ähnliche Artikel';
            default:
                return '';
        }
    }
    getFallbackParameters() {
        let params = {
            facetFilters: [],
        };
        if (this.kontinentValue) {
            params.facetFilters.push(`destination:${this.kontinentValue}`);
        }
        return params;
    }
    getQueryParameters() {
        let params = {
            facetFilters: [],
            filters: "",
        };
        if (this.destinationValue) {
            params.facetFilters.push(`destination:${this.destinationValue}`);
        }
        else if (this.kontinentValue) {
            params.facetFilters.push(`destination:${this.kontinentValue}`);
        }
        if (this.pageTypeValue) {
            params.filters = `this.pageTypeValue:${this.pageTypeValue}`;
        }
        return params;
    }
}
