import { Controller } from "@hotwired/stimulus";
import { useIntersection, useMatchMedia } from "stimulus-use";
import { GeoUtils } from "./mapbox/geoUtils";
import { Usercentrics } from "./utils/usercentrics";
import { clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll } from './utils/bodyScrollLock';
export default class default_1 extends Controller {
    // @ts-ignore
    element;
    static values = {
        geojsonUrl: String,
        mapboxAccessToken: String
    };
    static targets = [
        "map",
        "mapContainer",
        "stageContainer",
        "chapter",
        "showMapButton",
        "hideMapButton"
    ];
    map;
    geojson;
    isMobile = true;
    isMobileMapVisible = false;
    observer;
    isMapInitialized = false;
    markers = {};
    polygons = {};
    layersOnMap = [];
    hasAppeared = false;
    initialize() {
        this.intersectionObserverCallback = this.intersectionObserverCallback.bind(this);
    }
    connect() {
        useMatchMedia(this, {
            mediaQueries: {
                medium: "(min-width: 768px)"
            }
        });
        if ('IntersectionObserver' in window) {
            useIntersection(this);
        }
        this.setConsentLayer();
        window.addEventListener("consent_changed", (e) => {
            if (e.detail && e.detail.event == "consent_status") {
                this.setConsentLayer();
                if (this.isMapInitialized && Usercentrics.hasConsent('mapbox') === false) {
                    this.map?.remove();
                    this.isMapInitialized = false;
                }
                else if (Usercentrics.hasConsent('mapbox') && this.hasAppeared) {
                    this.initMap();
                }
            }
        });
        window.addEventListener("UC_UI_INITIALIZED", (e) => {
            this.setConsentLayer();
            if (this.isMapInitialized && Usercentrics.hasConsent('mapbox') === false) {
                this.map?.remove();
                this.isMapInitialized = false;
            }
            else if (Usercentrics.hasConsent('mapbox') && this.hasAppeared) {
                this.initMap();
            }
        });
    }
    disconnect() {
        clearAllBodyScrollLocks();
    }
    setConsentLayer() {
        if (this.hasMapTarget && Usercentrics.hasConsent('mapbox') === false) {
            this.mapTarget.innerHTML = Usercentrics.getMapboxOverlay();
        }
    }
    // noinspection JSUnusedGlobalSymbols
    markerShow(e) {
        let key = e.currentTarget.id;
        if (this.markers[key]) {
            this.addMarker(key, null, 0.8);
        }
        else {
            this.addPolygon(key);
        }
    }
    // noinspection JSUnusedGlobalSymbols
    markerHide(e) {
        let key = e.currentTarget.id;
        if (this.markers[key]) {
            this.addMarker(key, null);
        }
        else {
            this.removePolygon(key);
        }
    }
    // noinspection JSUnusedGlobalSymbols
    mapTargetDisconnected() {
        this.map?.remove();
    }
    // noinspection JSUnusedGlobalSymbols
    appear() {
        if (!this.isMobile) {
            this.hasAppeared = true;
            this.initMap();
        }
    }
    async initMap() {
        if (this.isMapInitialized || !Usercentrics.hasConsent('mapbox')) {
            return;
        }
        this.isMapInitialized = true;
        const response = await fetch(this.geojsonUrlValue);
        this.geojson = await (response ? response.json() : Promise.resolve(null));
        const [{}, mapboxgl, { default: localeDe }] = await Promise.all([
            // @ts-ignore
            import("mapbox-gl/dist/mapbox-gl.css"),
            import("mapbox-gl"),
            import("./mapbox/locale.de")
        ]);
        // Scroll-Observer
        this.observer = new IntersectionObserver(this.intersectionObserverCallback, {});
        this.stageContainerTargets.forEach((item) => this.observer.observe(item));
        // Karte
        const center = this.geojson.features
            .map((feature) => {
            if (feature.geometry.type === "MultiPolygon") {
                return feature.geometry.coordinates[0][0][0];
            }
            return feature.geometry.coordinates;
        })[0];
        this.map = new mapboxgl.Map({
            accessToken: this.mapboxAccessTokenValue,
            container: this.mapTarget, // container ID
            style: "mapbox://styles/umfulana/cl0c2flkh008c16mqtatiowt8", // style URL
            center: center,
            zoom: 6, // starting zoom
            renderWorldCopies: true,
            maxZoom: 15,
            attributionControl: true,
            cooperativeGestures: true,
            locale: localeDe,
            antialias: true,
            maxPitch: 0,
            dragRotate: false
        });
        this.map?.addControl(new mapboxgl.NavigationControl());
        await this.map.once("load");
        const layers = this.map?.getStyle().layers || [];
        // Find the index of the first symbol layer in the map style.
        let firstSymbolId;
        for (const layer of layers) {
            if (layer.type === "symbol") {
                firstSymbolId = layer.id;
                break;
            }
        }
        this.map?.addSource("tour", {
            "type": "geojson",
            "data": this.geojson
        });
        // Polygone zusammenfassen, damit nur ein Titel angezeigt wird, anstatt mehrere
        let geojsonForTitles = {
            features: this.geojson.features.map((feature) => {
                if (feature.geometry.type === "Polygon" || feature.geometry.type === "MultiPolygon") {
                    feature.geometry.coordinates = [
                        feature.geometry.coordinates
                            .sort((a, b) => {
                            return GeoUtils.calcPolygonArea(a[0]) - GeoUtils.calcPolygonArea(b[0]);
                        })
                            .pop()
                    ];
                }
                return feature;
            }),
            type: "FeatureCollection"
        };
        this.map?.addSource("tourTitleSource", {
            "type": "geojson",
            "data": geojsonForTitles
        });
        // Marker mit Text
        this.map?.addLayer({
            "id": "tour-titles",
            "type": "symbol",
            "source": "tourTitleSource",
            "layout": {
                // "icon-image": "location-dot-solid-red",
                // "icon-anchor": "bottom",
                // "icon-size": 0.6,
                "text-field": ["get", "name"],
                "text-offset": [0, 0],
                "text-variable-anchor": ["top", "bottom", "left", "right"],
                "text-justify": "auto",
                "text-size": 12
            },
            "paint": {
                "text-color": "#005c1f",
                "text-halo-color": "#fff",
                "text-halo-width": 2
            }
        });
        this.map?.on("mouseenter", "tour-titles", (e) => {
            e.target.getCanvas().style.cursor = "pointer";
            this.addPolygon(`region_${e.features[0]?.id}`);
        });
        this.map?.on("mouseleave", "tour-titles", (e) => {
            e.target.getCanvas().style.cursor = "";
            this.removePolygons();
        });
        this.map?.on("click", "tour-titles", (e) => {
            this.openModal(e, e.features[0]?.id);
        });
        this.geojson.features
            .filter((feature) => feature.geometry.type === "Point")
            .forEach((feature) => {
            const key = `region_${feature?.id}`;
            this.addMarker(key, feature.geometry.coordinates);
        });
        // Polygons
        this.geojson.features
            .filter((feature) => feature.geometry.type === "MultiPolygon")
            .forEach((feature) => {
            const key = `region_${feature?.id}`;
            if (!this.map?.getLayer(key)) {
                this.map?.addLayer({
                    "id": key,
                    "type": "fill",
                    "source": "tour",
                    "layout": {
                        visibility: "visible"
                    },
                    "paint": {
                        "fill-color": "#005c1f",
                        "fill-opacity": 0
                    },
                    filter: ["==", "$id", feature?.id]
                });
            }
        });
        // Fit to Bounds
        this.fitBounds();
    }
    openModal(e, id) {
        const key = `region_${id}`;
        const el = document.getElementById(key);
        if (el) {
            const modalController = this.application.getControllerForElementAndIdentifier(el, "modal");
            e.params = {
                url: `/places/_modal/${id}`
            };
            modalController?.open(e);
        }
    }
    async fitBounds() {
        if (!this.geojson.features.length) {
            return;
        }
        const mapboxgl = await import("mapbox-gl");
        const center = this.geojson.features
            .map((feature) => {
            if (feature.geometry.type === "MultiPolygon") {
                return feature.geometry.coordinates[0][0][0];
            }
            return feature.geometry.coordinates;
        })[0];
        // Create a 'LngLatBounds' with both corners at the first coordinate.
        const bounds = new mapboxgl.LngLatBounds(center.coordinates, center.coordinates);
        // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
        for (const feature of this.geojson.features) {
            if (feature.geometry.type === "Point") {
                try {
                    bounds.extend(feature.geometry.coordinates);
                }
                catch (e) {
                    // console.error(feature.properties.name, feature.geometry.coordinates)
                }
            }
            else if (feature.geometry.type === "MultiPolygon") {
                feature.geometry.coordinates.forEach((coordinateContainer) => {
                    coordinateContainer.forEach((coordinate) => {
                        try {
                            bounds.extend(coordinate);
                        }
                        catch (e) {
                            // console.error(feature.properties.name, coordinate)
                        }
                    });
                });
            }
        }
        this.map?.fitBounds(bounds, {
            padding: 50,
            maxZoom: 10
        });
    }
    // noinspection JSUnusedGlobalSymbols
    toggleMap() {
        if (this.isMobileMapVisible) {
            // Karte ausblenden
            enableBodyScroll(this.mapContainerTarget);
            this.mapContainerTarget.classList.add("hidden");
            if (this.hasShowMapButtonTarget) {
                this.showMapButtonTarget.classList.remove("hidden");
            }
            if (this.hasHideMapButtonTarget) {
                this.hideMapButtonTarget.classList.add("hidden");
            }
        }
        else {
            // Karte einblenden
            this.initMap();
            disableBodyScroll(this.mapContainerTarget, {
                reserveScrollBarGap: true,
            });
            this.mapContainerTarget.classList.remove("hidden");
            if (this.hasShowMapButtonTarget) {
                this.showMapButtonTarget.classList.add("hidden");
            }
            if (this.hasHideMapButtonTarget) {
                this.hideMapButtonTarget.classList.remove("hidden");
            }
        }
        this.map?.resize();
        this.isMobileMapVisible = !this.isMobileMapVisible;
    }
    // Tablet / Desktop
    // noinspection JSUnusedGlobalSymbols
    isMedium({ name, media, matches, event }) {
        this.isMobile = false;
        if (this.map) {
            // @ts-ignore
            this.map["_cooperativeGestures"] = true;
        }
    }
    // Mobile
    // noinspection JSUnusedGlobalSymbols
    notMedium({ name, media, matches, event }) {
        this.isMobile = true;
        if (this.map) {
            // @ts-ignore
            this.map["_cooperativeGestures"] = false;
        }
    }
    intersectionObserverCallback(entries, observer) {
        entries.forEach((entry) => {
            const target = entry.target;
            if (entry.isIntersecting) {
                // console.log(`Target #${target.id} is fully visible in the screen`);
                this.fitBounds();
            }
            else {
                // console.log(`Target #${target.id} is not visible in the screen`);
            }
        });
    }
    async addMarker(key, lngLat, scale = 0.5) {
        if (!this.isMapInitialized) {
            return;
        }
        if (!this.map?.getSource("tour")) {
            return;
        }
        const mapboxgl = await import("mapbox-gl");
        let m = null;
        if (this.markers[key]) {
            m = this.markers[key];
            m.remove();
        }
        let ll = m ? m.getLngLat() : lngLat;
        if (ll) {
            this.markers[key] = new mapboxgl.Marker({
                color: "#005c1f",
                scale: scale,
                draggable: false
            })
                // @ts-ignore
                .setLngLat(ll)
                // @ts-ignore
                .addTo(this.map);
            this.markers[key].getElement().addEventListener("mouseenter", (e) => {
                e.target.style.cursor = "pointer";
            });
            this.markers[key].getElement().addEventListener("mouseleave", (e) => {
                e.target.style.cursor = "";
            });
            this.markers[key].getElement().addEventListener("click", (e) => {
                this.openModal(e, parseInt(key.split("_")[1], 10));
            });
        }
    }
    addPolygon(key) {
        if (!this.isMapInitialized) {
            return;
        }
        if (!this.map?.getSource("tour")) {
            return;
        }
        const id = parseInt(key.split("_")[1], 10);
        if (!this.map?.getLayer(key + "_filled")) {
            this.map?.addLayer({
                "id": `${key}_filled`,
                "type": "fill",
                "source": "tour",
                "layout": {
                    visibility: "visible"
                },
                "paint": {
                    "fill-color": "#005c1f",
                    "fill-opacity": 0.4
                },
                filter: ["==", "$id", id]
            });
            this.layersOnMap.push(`${key}_filled`);
        }
    }
    removePolygon(key) {
        if (this.map?.getLayer(key + "_filled")) {
            this.map?.removeLayer(key + "_filled");
        }
    }
    removePolygons() {
        this.layersOnMap.forEach((key) => {
            if (this.map?.getLayer(key)) {
                this.map?.removeLayer(key);
            }
        });
    }
}
