import { Controller } from "@hotwired/stimulus";
export default class default_1 extends Controller {
    static targets = [
        "icon"
    ];
    static classes = [
        "iconChecked",
        "iconUnchecked",
        "toastBgColor"
    ];
    static values = {
        selected: Boolean,
        url: String,
        selectedTitle: String,
        unselectedTitle: String,
        tourId: String
    };
    toast;
    toastId = "wishlistToast";
    connect() {
        this.setTitle();
        this.setIcons();
    }
    setTitle() {
        if (this.selectedValue) {
            this.element.setAttribute("title", this.selectedTitleValue);
        }
        else {
            this.element.setAttribute("title", this.unselectedTitleValue);
        }
    }
    // noinspection JSUnusedGlobalSymbols
    iconTargetConnected() {
        this.setIcons();
    }
    setIcons() {
        if (!this.hasIconTarget) {
            return;
        }
        if (this.selectedValue) {
            this.iconTarget.innerHTML = this.iconHeartSolid;
        }
        else {
            this.iconTarget.innerHTML = this.iconHeartLight;
        }
    }
    async toggle(e) {
        e.preventDefault();
        e.stopPropagation();
        this.selectedValue = !this.selectedValue;
        this.setTitle();
        this.setIcons();
        this.showToast();
        await fetch(this.urlValue + (this.selectedValue ? "?addToWishlist=1" : "?removeFromWishlist=1"));
        this.dispatch("toggled", {
            detail: {
                tourId: this.tourIdValue,
                isSelected: this.selectedValue
            }
        });
    }
    showToast() {
        if (this.toast) {
            this.toast.remove();
        }
        const text = this.selectedValue ? "Erfolgreich hinzugefügt." : "Erfolgreich entfernt.";
        const html = `<div id="${this.toastId}" class="fixed transition-transform z-toast bottom-0 left-1/2 translate-y-full -translate-x-1/2 duration-300 pb-6 md:pb-12">
<div class="py-2 px-6 rounded-md text-white font-medium whitespace-nowrap ring-3 ring-white/50 ring-offset-4 ring-offset-transparent ${this.hasToastBgColorClass ? this.toastBgColorClass : 'bg-red-600'}">${text}</div>
</div>`;
        document.body.insertAdjacentHTML("beforeend", html);
        this.toast = document.querySelector(`#${this.toastId}`);
        setTimeout(_ => {
            this.toast?.classList.remove("translate-y-full");
        }, 150);
        setTimeout(_ => {
            this.toast?.remove();
        }, 3000);
    }
    // noinspection JSUnusedGlobalSymbols
    refresh(e) {
        if (this.tourIdValue === e.detail.tourId) {
            this.selectedValue = e.detail.isSelected;
            this.setTitle();
            this.setIcons();
        }
    }
    get iconHeartSolid() {
        let className = 'size-[1.15rem] text-red-600 group-hover:text-red-700';
        if (this.hasIconCheckedClass) {
            className = this.iconCheckedClasses.join(' ');
        }
        return `<svg class="${className}" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"/></svg>`;
    }
    get iconHeartLight() {
        let className = 'text-gray-400 size-[1.15rem] group-hover:text-red-600 transition-color';
        if (this.hasIconUncheckedClass) {
            className = this.iconUncheckedClasses.join(' ');
        }
        return `<svg class="${className}" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M244 130.6l-12-13.5-4.2-4.7c-26-29.2-65.3-42.8-103.8-35.8c-53.3 9.7-92 56.1-92 110.3v3.5c0 32.3 13.4 63.1 37.1 85.1L253 446.8c.8 .7 1.9 1.2 3 1.2s2.2-.4 3-1.2L443 275.5c23.6-22 37-52.8 37-85.1v-3.5c0-54.2-38.7-100.6-92-110.3c-38.5-7-77.8 6.6-103.8 35.8l-4.2 4.7-12 13.5c-3 3.4-7.4 5.4-12 5.4s-8.9-2-12-5.4zm34.9-57.1C311 48.4 352.7 37.7 393.7 45.1C462.2 57.6 512 117.3 512 186.9v3.5c0 36-13.1 70.6-36.6 97.5c-3.4 3.8-6.9 7.5-10.7 11l-184 171.3c-.8 .8-1.7 1.5-2.6 2.2c-6.3 4.9-14.1 7.5-22.1 7.5c-9.2 0-18-3.5-24.8-9.7L47.2 299c-3.8-3.5-7.3-7.2-10.7-11C13.1 261 0 226.4 0 190.4v-3.5C0 117.3 49.8 57.6 118.3 45.1c40.9-7.4 82.6 3.2 114.7 28.4c6.7 5.3 13 11.1 18.7 17.6l4.2 4.7 4.2-4.7c4.2-4.7 8.6-9.1 13.3-13.1c1.8-1.5 3.6-3 5.4-4.5z"/></svg>`;
    }
}
